import React from 'react';
import Header from '../components/header';
import Footers from '../components/footers';
import { Card } from 'flowbite-react';
import { IoIosTimer } from "react-icons/io";
import { BsSearch } from "react-icons/bs";
import { PiVideo } from "react-icons/pi";

const Features = () => {

    const cardsFeatures = [
        { id: 1, title: 'Adicionar Tópicos', description: 'Insira tópicos e subtópicos facilmente com uma interface intuitiva' },
        { id: 2, title: 'Preferências', description: 'Escolha entre os formatos Imagem + Teoria ou Vídeo + Teoria para uma jornada de aprendizagem personalizada' },
        { id: 3, title: 'Geração alimentada por IA', description: 'Nossos algoritmos avançados de IA analisam suas informações para gerar conteúdos abrangentes' },
        { id: 4, title: 'Estilos de aprendizagem', description: 'Acomode diferentes estilos de aprendizagem para focar em imagens, vídeos ou conteúdo textual' },
        { id: 5, title: 'Estudo Personalizado', description: 'Receba o material elaborado exclusivamente com base em suas preferências' },
        { id: 6, title: 'Pré-visualização em tempo real', description: 'Veja uma visualização em tempo real do seu conteúdo gerado antes de finalizar' },
        { id: 7, title: 'Conteúdo multilíngues', description: 'Gere imagens, vídeos ou conteúdos textuais de IA em mais de 10 idiomas' },
        { id: 8, title: 'Bate-papo Com Professor IA', description: 'Converse com o professor Ai para obter respostas às suas perguntas enquanto aprende' },
        { id: 9, title: 'Exporte o Conteúdo', description: 'Baixe seu conteúdo gerado em vários formatos para acesso offline' }
    ];

    const cardBenefit = [
        { id: 1, title: 'Eficiência de tempo', description: 'Economize horas de planejamento manual com a geração instantânea.', icon: <IoIosTimer className="text-gray-300" /> },
        { id: 2, title: 'Materiais aprimorados por IA', description: 'Garanta conteúdo de alta qualidade com recomendações baseadas em IA', icon: <BsSearch className="text-gray-300" /> },
        { id: 3, title: 'Aprendizagem interativa', description: 'Manter os usuários envolvidos com diferentes formatos de mídia', icon: <PiVideo className="text-gray-300" /> }
    ];

    return (
        <div className="relative dark:bg-black bg-gradient-to-b from-gray-800 via-gray-900 to-black min-h-screen flex flex-col">
            <Header isHome={false} className="sticky top-0 z-50" />

            <div className="flex-1 flex flex-col items-center justify-center text-center relative z-10 px-6">
                <h1 className="text-6xl font-black mt-14 max-md:text-3xl text-white">SOBRE</h1>
                <p className="text-center text-gray-400 mt-6 max-w-2xl font-medium max-md:text-xs">Elabore seu plano de estudos instantaneamente</p>
                <div className='mt-16 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-6 p-10 w-full max-w-screen-xl'>
                    {cardsFeatures.map((card) => (
                        <div key={card.id} className="flex flex-col items-center justify-center p-6 rounded-xl shadow-md bg-gray-800 text-center border border-gray-700 hover:scale-105 hover:shadow-2xl transition-all duration-300 ease-in-out">
                            <h5 className='text-lg font-semibold mt-3 text-white'>{card.title}</h5>
                            <p className='text-sm mt-2 text-gray-400'>{card.description}</p>
                        </div>
                    ))}
                </div>

                <h1 className="text-4xl font-black mt-14 max-md:text-2xl text-white">Benefícios</h1>
                <div className='mt-16 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-6 p-10 w-full max-w-screen-xl'>
                    {cardBenefit.map((card) => (
                        <div key={card.id} className="flex flex-col items-center justify-center p-6 rounded-xl shadow-md bg-gray-800 text-center border border-gray-700 hover:scale-105 hover:shadow-2xl transition-all duration-300 ease-in-out">
                            {card.icon}
                            <h5 className='text-lg font-semibold mt-3 text-white'>{card.title}</h5>
                            <p className='text-sm mt-2 text-gray-400'>{card.description}</p>
                        </div>
                    ))}
                </div>
            </div>

            <Footers className="sticky bottom-0 z-50" />
        </div>
    );
};

export default Features;
