import React from 'react';
import Header from '../components/header';
import Footers from '../components/footers';
import slide from '../res/img/about.svg';
import { useNavigate } from 'react-router-dom';
import { Button } from 'flowbite-react';
import { company, name } from '../constants';

const About = () => {

    const navigate = useNavigate();

    function redirectContact() {
        navigate("/contact");
    }

    return (
        <div className='min-h-screen flex flex-col bg-gradient-to-b from-gray-800 via-gray-900 to-black text-white'>
            <Header isHome={false} className="sticky top-0 z-50" />

            <div className='flex-1 flex flex-col items-center justify-center text-center px-6'>
                <h1 className="text-6xl font-black mt-14 max-md:text-3xl">Sobre</h1>
                <p className="text-gray-400 mt-6 max-w-2xl font-medium max-md:text-xs">
                    Bem-vindo ao {name}, o seu guia de estudos com IA de última geração oferecido a você por {company}!
                </p>
            </div>

            <div className="flex flex-col md:flex-row items-center justify-center px-7 max-md:px-3 pb-10 mt-14 w-full max-w-screen-xl mx-auto">
                <div className="md:w-1/2 h-full p-6 flex flex-col items-start justify-center text-left">
                    <h2 className="text-4xl font-black mb-4 max-md:text-2xl">Sobre Nós</h2>
                    <p className="text-gray-400 mb-4 max-md:text-center max-md:text-xs">
                        Na {company}, acreditamos no poder transformador da educação e nas infinitas possibilidades que a  tecnologia baseada em Inteligência Artificial abre.
                        É por isso que desenvolvemos {name}, um produto SaaS revolucionário projetado para tornar a criação de planos de estudo perfeita, eficiente e inteligente. De uma forma totalmente gratuita!
                    </p>
                </div>
                <div className="md:w-1/2 h-full flex justify-center">
                    <img src={slide} alt="Ilustração" className="w-full h-auto max-w-sm md:max-w-md" />
                </div>
            </div>

            <div className='flex flex-col items-center text-center px-6 max-md:px-3'>
                <h1 className="text-4xl font-black mt-14 max-md:text-2xl">Nossa Missão</h1>
                <p className="text-gray-400 mt-8 max-w-4xl max-md:text-xs">
                Nossa missão é oferecer recursos extras de educação gratuita a todo o Brasil e aos países onde o português é a língua principal. Nosso objetivo é capacitar estudantes e organizações de todos os níveis, permitindo a criação de experiências de aprendizagem excepcionais de forma simples e acessível. O {name} emprega as mais avançadas tecnologias de Inteligência Artificial para otimizar o processo de elaboração de guias de estudo personalizados, atendendo às necessidades individuais de cada educando. Com isso, buscamos desbloquear novos horizontes de excelência educacional.
                </p>
            </div>

            <div className='flex flex-col items-center text-center px-6 max-md:px-3'>
                <h1 className="text-4xl font-black mt-20 max-md:text-2xl">Junte-se a nós na jornada de aprendizagem</h1>
                <p className="text-gray-400 mt-8 max-w-4xl max-md:text-xs">
                Embarque em uma jornada de inovação e excelência educacional com o {name}. Seja você um estudante, um profissional ou uma organização em busca de aprimorar seus programas de aprendizagem, a {company} está comprometida em apoiá-lo em todas as etapas desse processo.
                </p>
                <Button onClick={redirectContact} className='max-w-xs my-10 border border-gray-500 bg-transparent text-white font-bold rounded-lg w-full hover:bg-gray-700 transition-all'>Contatos</Button>
            </div>

            <Footers className="sticky bottom-0 z-50" />
        </div>
    );
};

export default About;
