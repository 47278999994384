import React, { useEffect, useState } from 'react';
import { Navbar } from 'flowbite-react';
import { Flowbite } from 'flowbite-react';
import { name, serverURL, websiteURL } from '../constants';
import LogoComponent from './LogoComponent';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import axios from 'axios';
import { FaSearch } from 'react-icons/fa';

const Header = ({ isHome }) => {
  const navigate = useNavigate();
  const [admin, setAdmin] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    if (isHome && sessionStorage.getItem('uid') === null) {
      navigate("/signin");
    }
    async function dashboardData() {
      const postURL = serverURL + `/api/dashboard`;
      const response = await axios.post(postURL);
      sessionStorage.setItem('adminEmail', response.data.admin.email);
      if (response.data.admin.email === sessionStorage.getItem('email')) {
        setAdmin(true);
      }
    }
    
    if (sessionStorage.getItem('uid')) {
      setIsLoggedIn(true);
    }

    if (sessionStorage.getItem('adminEmail')) {
      if (sessionStorage.getItem('adminEmail') === sessionStorage.getItem('email')) {
        setAdmin(true);
      }
    } else {
      dashboardData();
    }
  }, []);

  function redirectSignIn() { navigate("/signin"); }
  function redirectAdmin() {
    sessionStorage.setItem('darkMode', false);
    window.location.href = websiteURL + '/dashboard';
  }
  function redirectFeatures() { navigate("/features"); }
  function redirectSignUp() { navigate("/signup"); }
  function redirectHome() { navigate("/home"); }
  function redirectGenerate() { navigate("/create"); }
  function redirectProfile() { navigate("/profile"); }
  function Logout() {
    sessionStorage.clear();
    showToast('Logout Successful');
    setIsLoggedIn(false);
    redirectSignIn();
  }
  function redirectPricing() { navigate('/pricing', { state: { header: true } }); }
  function redirectPricingTwo() { navigate('/pricing', { state: { header: false } }); }

  const showToast = (msg) => {
    toast(msg, {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
  };

  const handleSearch = () => {
    alert("Funcionalidade de pesquisa em desenvolvimento!");
  };

  return (
    <Flowbite>
      <Navbar fluid className="bg-gradient-to-b from-gray-900 via-gray-900 to-gray-800 dark:bg-gray-900 py-2 shadow-md mb-0 backdrop-blur-lg bg-opacity-80">
        <div className="container mx-auto flex flex-wrap items-center justify-between">
          <Navbar.Brand href={websiteURL} className="flex items-center">
            <LogoComponent />
            <span className="self-center text-xl font-semibold whitespace-nowrap text-white ml-2 shadow-lg">
              
            </span>
          </Navbar.Brand>
          <div className="flex md:order-2 ml-4"> {/* Ajustado para dar mais espaço entre a lupa e o menu */}
            <button onClick={handleSearch} className="p-2 text-white hover:text-gray-200 dark:text-gray-400 dark:hover:text-white">
              <FaSearch className="w-5 h-5" />
            </button>
            <Navbar.Toggle className="ml-3 inline-flex items-center p-2 text-sm text-white rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" />
          </div>
          <Navbar.Collapse className="w-full md:w-auto md:order-1 md:ml-[-60px] md:ml-auto">
            <Navbar.Link onClick={redirectPricingTwo} className="block py-2 px-4 text-sm text-white hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-gray-900 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white">
              Apoie
            </Navbar.Link>
            <Navbar.Link onClick={redirectFeatures} className="block py-2 px-4 text-sm text-white hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-gray-900 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white">
              Sobre
            </Navbar.Link>
            <Navbar.Link onClick={redirectHome} className="block py-2 px-4 text-sm text-white hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-gray-900 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white">
              Início
            </Navbar.Link>
            {isLoggedIn ? (
              <>
                <Navbar.Link onClick={redirectProfile} className="block py-2 px-4 text-sm text-white hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-gray-900 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white">
                  Perfil
                </Navbar.Link>
                {admin && (
                  <Navbar.Link onClick={redirectAdmin} className="block py-2 px-4 text-sm text-white hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-gray-900 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white">
                    Admin
                  </Navbar.Link>
                )}
                <Navbar.Link onClick={Logout} className="block py-2 px-4 text-sm text-white hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-gray-900 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white">
                  Sair
                </Navbar.Link>
                <Navbar.Link 
  onClick={redirectGenerate} 
  className="block py-2 px-4 text-sm text-white hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-gray-900 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white relative"
>
  Criar Estudo
  <span className="absolute bottom-0 left-0 w-full h-[2px] bg-gradient-to-r from-blue-600 via-indigo-500 to-purple-600"></span>
</Navbar.Link>

              </>
            ) : (
              <>
<Navbar.Link 
  onClick={redirectSignIn} 
  className="block py-2 px-4 text-sm text-white hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-gray-900 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white relative"
>
  Entrar
  <span className="absolute bottom-0 left-0 w-full h-[2px] bg-gradient-to-r from-blue-600 via-indigo-500 to-purple-600"></span>
</Navbar.Link>

                <Navbar.Link onClick={redirectSignUp} className="block py-2 px-4 text-sm text-white bg-black rounded md:bg-transparent md:text-white dark:text-white">
                  Cadastro 
                </Navbar.Link>
              </>
            )}
          </Navbar.Collapse>
        </div>
      </Navbar>
    </Flowbite>
  );
};

export default Header;
