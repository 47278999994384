import { Drawer, Navbar, Sidebar } from 'flowbite-react';
import React, { useEffect, useState } from 'react';
import LogoComponent from '../components/LogoComponent';
import { FiMenu, FiX } from 'react-icons/fi';
import DarkModeToggle from '../components/DarkModeToggle';
import TruncatedText from '../components/TruncatedText';
import { useLocation, useNavigate } from 'react-router-dom';
import { IoIosArrowDown } from "react-icons/io";
import StyledText from '../components/styledText';
import YouTube from 'react-youtube';
import { toast } from 'react-toastify';
import { logo, name, serverURL, websiteURL } from '../constants';
import axios from 'axios';
import { FaCheck } from "react-icons/fa";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import html2pdf from 'html2pdf.js';
import ShareOnSocial from 'react-share-on-social';
import ChatWidget from '../components/chatWidget';
import NotesWidget from '../components/notesWidget';
import { AiFillHome } from "react-icons/ai";
import { HiDownload } from "react-icons/hi";
import { RiShareFill } from "react-icons/ri";

const Course = () => {
    const [isReading, setIsReading] = useState(false); // Controla se a leitura está ativa
    const [utterance, setUtterance] = useState(null); // Armazena o objeto de leitura
    const [currentText, setCurrentText] = useState(''); // Inicializa com string vazia
    const [currentUtterance, setCurrentUtterance] = useState(null); // Mantém referência do utterance atual
    const [theory, setTheory] = useState(''); // Inicializa o estado de 'theory' com string vazia
    const [isOpen, setIsOpen] = useState(false);
    const [key, setkey] = useState('');
    const { state } = useLocation();
    const { mainTopic, type, courseId, end, pass, lang } = state || {};
    const jsonData = JSON.parse(sessionStorage.getItem('jsonData'));
    const storedTheme = sessionStorage.getItem('darkMode');
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [selected, setSelected] = useState(''); 
    const [media, setMedia] = useState('');
    const [percentage, setPercentage] = useState(0);
    const [isComplete, setIsCompleted] = useState(false);
    const navigate = useNavigate();
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');

    // Controle de vozes
    const [voices, setVoices] = useState([]);
    const [selectedVoice, setSelectedVoice] = useState(''); // Armazena a voz selecionada

    // Atualiza as vozes disponíveis
    useEffect(() => {
        const loadVoices = () => {
            setVoices(window.speechSynthesis.getVoices());
        };

        loadVoices(); // Carrega as vozes ao inicializar

        // Recarrega as vozes quando a lista de vozes mudar (ex: em alguns navegadores)
        speechSynthesis.onvoiceschanged = loadVoices;
    }, []);

    // Atualiza o texto e inicia a leitura quando o 'theory' mudar
    useEffect(() => {
        if (theory && theory !== currentText) {
            setCurrentText(theory); // Atualiza o texto atual, mas não inicia a leitura
            // Remova ou comente a linha abaixo para evitar a leitura automática
            // const newUtterance = readTextAloud(theory); // NÃO iniciar a leitura automaticamente
            setCurrentUtterance(null); // Opcional: apenas atualiza o estado da referência do utterance, se necessário
        }
    }, [theory]);

    const cleanText = (text) => {
        // Remove as tags HTML
        let cleanText = text.replace(/<\/?[^>]+(>|$)/g, ""); // Remove todas as tags HTML
    
        // Substitui múltiplos espaços e quebras de linha por um único espaço
        cleanText = cleanText.replace(/\s+/g, ' ').trim();
    
        return cleanText;
    };

    let previousTopic = '';

    async function searchMaterials() {
        // Esperando a página carregar completamente para pegar o tema atualizado
        await waitForElement("p.text-2xl.font-extrabold.text-gray-900.dark\\:text-gray-100.tracking-wide.mb-6.text-center");
    
        // Pegando a temática diretamente da tela
        const topicElement = document.querySelector("p.text-2xl.font-extrabold.text-gray-900.dark\\:text-gray-100.tracking-wide.mb-6.text-center");
        if (!topicElement) {
            console.error("Elemento de temática não encontrado.");
            return;
        }
    
        const topic = topicElement.innerText.trim();
    
        // Verificar se o tema mudou
        if (topic !== previousTopic) {
            previousTopic = topic;
            // Exibir o botão para consultar os materiais
            displaySearchButton(topic);
        }
    }
    
    // Função para esperar o elemento ser carregado na página
    function waitForElement(selector) {
        return new Promise((resolve) => {
            const interval = setInterval(() => {
                if (document.querySelector(selector)) {
                    clearInterval(interval);
                    resolve();
                }
            }, 100); // Verifica a cada 100ms
        });
    }
    
    function displaySearchButton(topic) {
        document.getElementById('materialsContainer').innerHTML = `
            <div class="material-container">
                <p class="material-text">
                • Veja também outros materias complementares sobre o subtópico "<strong>${topic}</strong>". Fique por dentro dos mais completos artigos!
                </p>
                <button id="consultMaterialsButton" class="consult-button">
                     <strong>| ACESSAR REPOSITÓRIO |</strong>
                </button>
                <div id="materialsLinkContainer" class="materials-link-container"></div>
            </div>
        `;
    
        // Adiciona o efeito de hover no botão
        document.getElementById('consultMaterialsButton').addEventListener('mouseenter', () => {
            document.getElementById('consultMaterialsButton').classList.add('hover');
        });
    
        document.getElementById('consultMaterialsButton').addEventListener('mouseleave', () => {
            document.getElementById('consultMaterialsButton').classList.remove('hover');
        });
    
        // Adiciona o evento de clique ao botão
        document.getElementById('consultMaterialsButton').addEventListener('click', () => {
            generateAndDisplayLink(topic);
        });
    }
    
    function generateAndDisplayLink(topic) {
        const formattedTopic = topic.split(" ").join("+");
        const url = `https://archive.org/search?query=${formattedTopic}&sin=TXT&and%5B%5D=language%3A%22Portuguese%22`;
    
        // Exibe o link gerado abaixo do botão com estilo mais suave
        document.getElementById('materialsLinkContainer').innerHTML = `
            <p class="materials-link-text">
                &nbsp; Explore esta curada seleção de materiais adicionais, provenientes de repositórios públicos:
            </p>
            <a href="${url}" target="_blank" class="materials-link">
                <strong> | VER | </strong>
            </a>
        `;
    }
    
    // Função para verificar periodicamente se o tema mudou
    setInterval(searchMaterials, 5000); // Verifica a cada 5 segundos
    
    const VideoSearchComponent = () => {
        const [videoLink, setVideoLink] = useState(null);
        const [content, setContent] = useState('');
        const [loading, setLoading] = useState(false);
    
        // Função para pegar o conteúdo da página (simula o que seria extraído de p.text-2xl)
        const getContent = () => {
            const contentElement = document.querySelector('p.text-2xl.font-extrabold.text-gray-900.dark\\:text-gray-100.tracking-wide.mb-6.text-center');
            if (contentElement) {
                setContent(contentElement.innerText);
            }
        };
    
        // Função que gera o link de pesquisa do YouTube
        const generateYouTubeLink = (query) => {
            const searchQuery = encodeURIComponent(query + " YouTube");
            return `https://www.youtube.com/results?search_query=${searchQuery}`;
        };
    
        const handleVideoClick = () => {
            setLoading(true);
    
            // Gerando a URL de pesquisa no YouTube
            const youtubeLink = generateYouTubeLink(content);
            setVideoLink(youtubeLink);
    
            setLoading(false);
        };
    
        // Atualiza o conteúdo a cada 5 segundos (não chama handleVideoClick automaticamente)
        useEffect(() => {
            getContent();
        }, []);
    
        return (
            <div className="video-container">
                <div style={{ marginBottom: '12px' }}>
                    <p className="material-text">
                    • Selecionamos para você vídeos relacionados ao tema! Aceite nossa sugestão e expanda seu conhecimento!
                    </p><strong>
                    <button
                        onClick={handleVideoClick}
                        disabled={loading}
                        className="consult-button"
                    >
                        {loading ? "Carregando..." : "| ACESSAR REPOSITÓRIO |"}
                    </button></strong>
                </div>
    
                {/* Exibe o vídeo somente após o clique */}
                {videoLink && (
                    <div style={{}}>
                        <p style={{ }}>&nbsp; Explore esta curada seleção de vídeos adicionais, provenientes de repositórios públicos:</p>
                        <strong><a
                            href={videoLink}
                            target="_blank"
                            className="materials-link"
                        >
                            | VER |
                        </a></strong>
                    </div>
                )}
            </div>
        );
    };
    
    // Função para iniciar a leitura
    const readTextAloud = (text) => {
        if (window.speechSynthesis.speaking) {
            window.speechSynthesis.cancel(); // Cancela leitura anterior, se houver
        }

        const cleanedText = cleanText(text); // Limpa o texto

        const utterance = new SpeechSynthesisUtterance(cleanedText);
        utterance.lang = 'pt-BR';
        utterance.volume = 1;
        utterance.rate = 1;
        utterance.pitch = 1;

        // Atribuindo a voz selecionada
        const selectedVoiceObject = voices.find(voice => voice.name === selectedVoice);
        if (selectedVoiceObject) {
            utterance.voice = selectedVoiceObject;
        }

        // Inicia a leitura do texto
        window.speechSynthesis.speak(utterance);

        // Atualiza os estados
        setIsReading(true);
        setUtterance(utterance); // Armazena a instância da leitura

        // Quando a leitura terminar, atualiza o estado
        utterance.onend = () => setIsReading(false);

        return utterance;
    };

    // Função para reiniciar a leitura
    const handleReadText = () => {
        if (utterance && window.speechSynthesis.speaking) {
            window.speechSynthesis.cancel(); // Cancela leitura se estiver em andamento
        }
        const newUtterance = readTextAloud(currentText); // Chama a função que inicia a leitura
        setCurrentUtterance(newUtterance); // Atualiza a referência do utterance
    };

    // Função para pausar a leitura
    const handlePauseReading = () => {
        if (window.speechSynthesis.speaking) {
            window.speechSynthesis.pause(); // Pausa a leitura
        }
    };

    // Função para retomar a leitura
    const handleResumeReading = () => {
        if (window.speechSynthesis.paused) {
            window.speechSynthesis.resume(); // Retoma a leitura se estiver pausada
        }
    };

    // Função para lidar com a mudança de voz
    const handleVoiceChange = (e) => {
        setSelectedVoice(e.target.value); // Atualiza a voz selecionada
    };
    async function redirectExam() {
        const id = toast.loading("Aguarde! Isso pode demorar um pouco...");
        const mainTopicExam = jsonData[mainTopic.toLowerCase()];
        let subtopicsString = '';
        mainTopicExam.map((topicTemp) => {
            let titleOfSubTopic = topicTemp.title;
            subtopicsString = subtopicsString + ' , ' + titleOfSubTopic;
        });

        const postURL = serverURL + '/api/aiexam';
        const response = await axios.post(postURL, { courseId, mainTopic, subtopicsString, lang });
        if (response.data.success) {
            const element = document.documentElement;
            if (element.requestFullscreen) {
                element.requestFullscreen();
            } else if (element.mozRequestFullScreen) { // Firefox
                element.mozRequestFullScreen();
            } else if (element.webkitRequestFullscreen) { // Chrome, Safari, Opera
                element.webkitRequestFullscreen();
            } else if (element.msRequestFullscreen) { // IE/Edge
                element.msRequestFullscreen();
            } else {
                console.error('Modo de tela cheia não suportado pelo navegador.');
            }
            let questions = JSON.parse(response.data.message);
            navigate('/exam', { state: { topic: mainTopic, courseId: courseId, questions: questions } });
            toast.update(id, { render: "Iniciando Prova", type: "success", isLoading: false, autoClose: 3000, hideProgressBar: false, closeOnClick: true });
        } else {
            toast.update(id, { render: "Erro Interno no Servidor", type: "error", isLoading: false, autoClose: 3000, hideProgressBar: false, closeOnClick: true });
        }
    }


    async function htmlDownload() {
        const id = toast.loading("Exportando, aguarde...")
        // Generate the combined HTML content
        const combinedHtml = await getCombinedHtml(mainTopic, jsonData[mainTopic.toLowerCase()]);

        // Create a temporary div element
        const tempDiv = document.createElement('div');
        tempDiv.style.width = '100%';  // Ensure div is 100% width
        tempDiv.style.height = '100%';  // Ensure div is 100% height
        tempDiv.innerHTML = combinedHtml;
        document.body.appendChild(tempDiv);

        // Create the PDF options
        const options = {
            filename: `${mainTopic}.pdf`,
            image: { type: 'jpeg', quality: 1 },
            margin: [15, 15, 15, 15],
            pagebreak: { mode: ["avoid-all", "css", "legacy"] },
            html2canvas: {
                scale: 2,
                logging: false,
                scrollX: 0,
                scrollY: 0,
                useCORS: true
            },
            jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' },
        };

        // Generate the PDF
        html2pdf().from(tempDiv).set(options).save().then(() => {
            // Save the PDF
            document.body.removeChild(tempDiv);
            toast.update(id, { render: "Done!", type: "success", isLoading: false, autoClose: 3000, hideProgressBar: false, closeOnClick: true });
        });
    }


    async function getCombinedHtml(mainTopic, topics) {


        async function toDataUrl(url) {
            return new Promise((resolve, reject) => {
                const xhr = new XMLHttpRequest();

                xhr.onload = function () {
                    const reader = new FileReader();
                    reader.onloadend = function () {
                        resolve(reader.result);
                    };
                    reader.readAsDataURL(xhr.response);
                };

                xhr.onerror = function () {
                    reject({
                        status: xhr.status,
                        statusText: xhr.statusText,
                    });
                };

                xhr.open("GET", url);
                xhr.responseType = "blob";
                xhr.send();
            }).catch(error => {
                console.error(`Failed to fetch image at ${url}:`, error);
                return ''; // Fallback or placeholder
            });
        }

        const topicsHtml = topics.map(topic => `
            <h3 style="font-size: 18pt; font-weight: bold; margin: 0; margin-top: 15px;">${topic.title}</h3>
            ${topic.subtopics.map(subtopic => `
                <p style="font-size: 16pt; margin-top: 10px;">${subtopic.title}</p>
            `).join('')}
        `).join('');

        const theoryPromises = topics.map(async topic => {
            const subtopicPromises = topic.subtopics.map(async (subtopic, index, array) => {
                const imageUrl = type === 'text & image course' ? await toDataUrl(subtopic.image) : ``;
                console.log(imageUrl);
                return `
                <div>
                    <p style="font-size: 16pt; margin-top: 20px; font-weight: bold;">
                        ${subtopic.title}
                    </p>
                    <div style="font-size: 12pt; margin-top: 15px;">
                        ${subtopic.done
                        ? `
                                ${type === 'text & image course'
                            ? (imageUrl ? `<img style="margin-top: 10px;" src="${imageUrl}" alt="${subtopic.title} image">` : `<a style="color: #0000FF;" href="${subtopic.image}" target="_blank">View example image</a>`)
                            : `<a style="color: #0000FF;" href="https://www.youtube.com/watch?v=${subtopic.youtube}" target="_blank" rel="noopener noreferrer">Watch the YouTube video on ${subtopic.title}</a>`
                        }
                                <div style="margin-top: 10px;">${subtopic.theory}</div>
                            `
                        : `<div style="margin-top: 10px;">Please visit ${subtopic.title} topic to export as PDF. Only topics that are completed will be added to the PDF.</div>`
                    }
                    </div>
                </div>
            `;
            });
            const subtopicHtml = await Promise.all(subtopicPromises);
            return `
                <div style="margin-top: 30px;">
                    <h3 style="font-size: 18pt; text-align: center; font-weight: bold; margin: 0;">
                        ${topic.title}
                    </h3>
                    ${subtopicHtml.join('')}
                </div>
            `;
        });
        const theoryHtml = await Promise.all(theoryPromises);

        return `
        <div class="html2pdf__page-break" 
             style="display: flex; align-items: center; justify-content: center; text-align: center; margin: 0 auto; max-width: 100%; height: 11in;">
            <h1 style="font-size: 30pt; font-weight: bold; margin: 0;">
                ${mainTopic}
            </h1>
        </div>
        <div class="html2pdf__page-break" style="text-align: start; margin-top: 30px; margin-right: 16px; margin-left: 16px;">
            <h2 style="font-size: 24pt; font-weight: bold; margin: 0;">Index</h2>
            <br>
            <hr>
            ${topicsHtml}
        </div>
        <div style="text-align: start; margin-right: 16px; margin-left: 16px;">
            ${theoryHtml.join('')}
        </div>
        `;
    }



    const CountDoneTopics = () => {
        let doneCount = 0;
        let totalTopics = 0;

        jsonData[mainTopic.toLowerCase()].forEach((topic) => {

            topic.subtopics.forEach((subtopic) => {

                if (subtopic.done) {
                    doneCount++;
                }
                totalTopics++;
            });
        });
        totalTopics = totalTopics + 1;
        if(pass){
            totalTopics = totalTopics - 1;
        }
        const completionPercentage = Math.round((doneCount / totalTopics) * 100);
        setPercentage(completionPercentage);
        if (completionPercentage >= 85) {
            setIsCompleted(true);
        }
    }

    const opts = {
        height: '390',
        width: '640',
    };

    const optsMobile = {
        height: '250px',
        width: '100%',
    };

    async function finish() {
        if (sessionStorage.getItem('first') === 'true') {
            if (!end) {
                const today = new Date();
                const formattedDate = today.toLocaleDateString('en-GB');
                navigate('/certificate', { state: { courseTitle: mainTopic, end: formattedDate } });
            } else {
                navigate('/certificate', { state: { courseTitle: mainTopic, end: end } });
            }

        } else {
            const dataToSend = {
                courseId: courseId
            };
            try {
                const postURL = serverURL + '/api/finish';
                const response = await axios.post(postURL, dataToSend);
                if (response.data.success) {
                    const today = new Date();
                    const formattedDate = today.toLocaleDateString('en-GB');
                    sessionStorage.setItem('first', 'true');
                    sendEmail(formattedDate);
                } else {
                    finish()
                }
            } catch (error) {
                finish()
            }
        }
    }

    async function sendEmail(formattedDate) {
        const userName = sessionStorage.getItem('mName');
        const email = sessionStorage.getItem('email');
        const html = `<!DOCTYPE html>
        <html lang="pt-BR">
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0, shrink-to-fit=no" />
            <title>Certificado de Participação</title>
            <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap">
            <style>
            body {
                font-family: 'Roboto', sans-serif;
                text-align: center;
                background-color: #fff;
                margin: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100vh;
            }
        
            .certificate {
                border: 10px solid #000;
                max-width: 600px;
                margin: 20px auto;
                padding: 50px;
                background-color: #fff;
                position: relative;
                color: #000;
                text-align: center;
            }
        
            h1 {
                font-weight: 900;
                font-size: 24px;
                margin-bottom: 10px;
            }
        
            h4 {
                font-weight: 900;
                text-align: center;
                font-size: 20px;
            }
        
            h2 {
                font-weight: 700;
                font-size: 18px;
                margin-top: 10px;
                margin-bottom: 5px;
                text-decoration: underline;
            }
        
            h3 {
                font-weight: 700;
                text-decoration: underline;
                font-size: 16px;
                margin-top: 5px;
                margin-bottom: 10px;
            }
        
            p {
                font-weight: 400;
                line-height: 1.5;
            }
        
            img {
                width: 40px;
                height: 40px;
                margin-right: 10px;
                text-align: center;
                align-self: center;
            }
            </style>
        </head>
        <body>
        
        <div class="certificate">
        <h1>Certificado de Participação 🥇</h1>
        <p>Isto é para certificar que</p>
        <h2>${userName}</h2>
        <p>concluiu com sucesso o módulo de</p>
        <h3>${mainTopic}</h3>
        <p>sobre ${formattedDate}.</p>
    
        <div class="signature">
            <img src=${logo}>
            <h4>${name}</h4>
        </div>
    </div>
        
        </body>
        </html>`;

        try {
            const postURL = serverURL + '/api/sendcertificate';
            await axios.post(postURL, { html, email }).then(res => {
                navigate('/certificate', { state: { courseTitle: mainTopic, end: formattedDate } });
            }).catch(error => {
                navigate('/certificate', { state: { courseTitle: mainTopic, end: formattedDate } });
            });

        } catch (error) {
            navigate('/certificate', { state: { courseTitle: mainTopic, end: formattedDate } });
        }

    }

    useEffect(() => {
        loadMessages()
        const CountDoneTopics = () => {
            let doneCount = 0;
            let totalTopics = 0;

            jsonData[mainTopic.toLowerCase()].forEach((topic) => {

                topic.subtopics.forEach((subtopic) => {

                    if (subtopic.done) {
                        doneCount++;
                    }
                    totalTopics++;
                });
            });
            totalTopics = totalTopics + 1;
            if(pass){
                doneCount = doneCount + 1;
            }
            const completionPercentage = Math.round((doneCount / totalTopics) * 100);
            setPercentage(completionPercentage);
            if (completionPercentage >= '85') {
                setIsCompleted(true);
            }
        }

        if (!mainTopic) {
            navigate("/create");
        } else {
            if (percentage >= '85') {
                setIsCompleted(true);
            }

            const mainTopicData = jsonData[mainTopic.toLowerCase()][0];
            const firstSubtopic = mainTopicData.subtopics[0];
            firstSubtopic.done = true
            setSelected(firstSubtopic.title)
            setTheory(firstSubtopic.theory);

            if (type === 'video & text course') {
                setMedia(firstSubtopic.youtube);
            } else {
                setMedia(firstSubtopic.image)

            }
            sessionStorage.setItem('jsonData', JSON.stringify(jsonData));
            CountDoneTopics();

        }

    }, []);

    const handleSelect = (topics, sub) => {

        const mTopic = jsonData[mainTopic.toLowerCase()].find(topic => topic.title === topics);
        const mSubTopic = mTopic?.subtopics.find(subtopic => subtopic.title === sub);
    
        if (mSubTopic.theory === '' || mSubTopic.theory === undefined || mSubTopic.theory === null) {
            if (type === 'video & text course') {
    
                const query = `${mSubTopic.title} ${mainTopic} in Portuguese`;
                const id = toast.loading("Aguarde! Isso pode demorar um pouco...");
                sendVideo(query, topics, sub, id, mSubTopic.title);
    
            } else {
    
                const prompt = `Strictly in ${lang}, provide a thorough and complete explanation of the subtopic ${mSubTopic.title} within the broader context of ${mainTopic}. The explanation should be highly detailed, academically rigorous, and include up-to-date theoretical knowledge and insights. Make sure to include a variety of examples—both current and classic—that are highly relevant to the subtopic. If applicable, provide a step-by-step breakdown of processes or concepts, and create diagrams or frameworks to illustrate key points. Ensure that the content is comprehensive, creative, and fully aligned with modern trends and human interests related to this field.`;
                const promptImage = `Search for images in Portuguese that are strictly relevant to the subtopic of ${mSubTopic.title} within ${mainTopic}. The images should focus primarily on visual aids, such as diagrams, flowcharts, mind maps, or other schematic representations that enhance understanding of the theoretical concepts being explained. These visuals should be clean, modern, and directly related to the content of the course, providing clear and informative depictions of the topic. Prioritize images with text, labels, and descriptions in Portuguese to ensure alignment with the course language. Avoid unrelated or generic images, and prioritize images that complement the academic material in a meaningful way.`;
                const id = toast.loading("Aguarde! Isso pode demorar um pouco...");
                sendPrompt(prompt, promptImage, topics, sub, id);
    
            }
        } else {
            setSelected(mSubTopic.title);
            setTheory(mSubTopic.theory);
            if (type === 'video & text course') {
                setMedia(mSubTopic.youtube);
            } else {
                setMedia(mSubTopic.image);
            }
        }
        // Chama a busca por materiais públicos quando um tópico é selecionado
        searchMaterials();
    };
    
    async function sendPrompt(prompt, promptImage, topics, sub, id) {
        const dataToSend = {
            prompt: prompt,
        };
        try {
            const postURL = serverURL + '/api/generate';
            const res = await axios.post(postURL, dataToSend);
            const generatedText = res.data.text;
            const htmlContent = generatedText;
            try {
                const parsedJson = htmlContent;
                sendImage(parsedJson, promptImage, topics, sub, id);
            } catch (error) {
                //sendPrompt(prompt, promptImage, topics, sub, id)
            }
    
        } catch (error) {
            //sendPrompt(prompt, promptImage, topics, sub, id)
        }
    }
    
    async function sendImage(parsedJson, promptImage, topics, sub, id) {
        const dataToSend = {
            prompt: promptImage,
        };
        try {
            const postURL = serverURL + '/api/image';
            const res = await axios.post(postURL, dataToSend);
            try {
                const generatedText = res.data.url;
                sendData(generatedText, parsedJson, topics, sub, id);
            } catch (error) {
                //sendImage(parsedJson, promptImage, topics, sub, id)
            }
    
        } catch (error) {
            //sendImage(parsedJson, promptImage, topics, sub, id)
        }
    }
    
    async function sendData(image, theory, topics, sub, id) {
    
        const mTopic = jsonData[mainTopic.toLowerCase()].find(topic => topic.title === topics);
        const mSubTopic = mTopic?.subtopics.find(subtopic => subtopic.title === sub);
        mSubTopic.theory = theory;
        mSubTopic.image = image;
        setSelected(mSubTopic.title);
    
        toast.update(id, { render: "Done!", type: "success", isLoading: false, autoClose: 3000, hideProgressBar: false, closeOnClick: true });
        setTheory(theory);
        if (type === 'video & text course') {
            setMedia(mSubTopic.youtube);
        } else {
            setMedia(image);
        }
        mSubTopic.done = true;
        updateCourse();
    }
    
    async function sendDataVideo(image, theory, topics, sub, id) {
    
        const mTopic = jsonData[mainTopic.toLowerCase()].find(topic => topic.title === topics);
        const mSubTopic = mTopic?.subtopics.find(subtopic => subtopic.title === sub);
        mSubTopic.theory = theory;
        mSubTopic.youtube = image;
        setSelected(mSubTopic.title);
    
        toast.update(id, { render: "Done!", type: "success", isLoading: false, autoClose: 3000, hideProgressBar: false, closeOnClick: true });
        setTheory(theory);
        if (type === 'video & text course') {
            setMedia(image);
        } else {
            setMedia(mSubTopic.image);
        }
        mSubTopic.done = true;
        updateCourse();
    
    }
    
    async function updateCourse() {
        CountDoneTopics();
        sessionStorage.setItem('jsonData', JSON.stringify(jsonData));
        const dataToSend = {
            content: JSON.stringify(jsonData),
            courseId: courseId
        };
        try {
            const postURL = serverURL + '/api/update';
            await axios.post(postURL, dataToSend);
        } catch (error) {
            //updateCourse()
        }
    }
    
    async function sendVideo(query, mTopic, mSubTopic, id, subtop) {
        const dataToSend = {
            prompt: query,
        };
        try {
            const postURL = serverURL + '/api/yt';
            const res = await axios.post(postURL, dataToSend);
    
            try {
                const generatedText = res.data.url;
                sendTranscript(generatedText, mTopic, mSubTopic, id, subtop);
            } catch (error) {
                //sendVideo(query, mTopic, mSubTopic, id, subtop)
            }
    
        } catch (error) {
            //sendVideo(query, mTopic, mSubTopic, id, subtop)
        }
    }
    
    async function sendTranscript(url, mTopic, mSubTopic, id, subtop) {
        const dataToSend = {
            prompt: url,
        };
        try {
            const postURL = serverURL + '/api/transcript';
            const res = await axios.post(postURL, dataToSend);
    
            try {
                const generatedText = res.data.url;
                const allText = generatedText.map(item => item.text);
                const concatenatedText = allText.join(' ');
                const prompt = `Strictly in ${lang}, Summarize this theory in a teaching way :- ${concatenatedText}.`;
                sendSummery(prompt, url, mTopic, mSubTopic, id);
            } catch (error) {
                const prompt = `Strictly in ${lang}, In an academic and professional way, explain to me about this subtopic of ${mainTopic} With the best possible current and classic examples of :- ${subtop}. Please Strictly Don't Give Additional Resources And Images.`;
                sendSummery(prompt, url, mTopic, mSubTopic, id);
            }
    
        } catch (error) {
            const prompt = `Strictly in ${lang}, Explain me about this subtopic of ${mainTopic} with examples :- ${subtop}.  Please Strictly Don't Give Additional Resources And Images.`;
            sendSummery(prompt, url, mTopic, mSubTopic, id);
        }
    }
    
    async function sendSummery(prompt, url, mTopic, mSubTopic, id) {
        const dataToSend = {
            prompt: prompt,
        };
        try {
            const postURL = serverURL + '/api/generate';
            const res = await axios.post(postURL, dataToSend);
            const generatedText = res.data.text;
            const htmlContent = generatedText;
            try {
                const parsedJson = htmlContent;
                sendDataVideo(url, parsedJson, mTopic, mSubTopic, id);
            } catch (error) {
                //sendSummery(prompt, url, mTopic, mSubTopic, id)
            }
    
        } catch (error) {
            //sendSummery(prompt, url, mTopic, mSubTopic, id)
        }
    }
    

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleOpenClose = (keys) => {
        setIsOpen(!isOpen)
        setkey(keys);
    };

    const defaultMessage = `<p>Olá! Serei seu mentor aqui no Chat. Vou esclarecer suas dúvida sobre o curso "<strong>${mainTopic}"</strong>. Pergunte-me o que desejar! Estou aqui para tirar todas as suas dúvidas.</p>`;
    const defaultPrompt = `Tenho uma dúvida sobre este assunto: - ${mainTopic}. Por favor, esclareça minha dúvida em breve :- `;

    const loadMessages = async () => {
        try {
            const jsonValue = sessionStorage.getItem(mainTopic);
            if (jsonValue !== null) {
                setMessages(JSON.parse(jsonValue));
            } else {
                const newMessages = [...messages, { text: defaultMessage, sender: 'bot' }];
                setMessages(newMessages);
                await storeLocal(newMessages);
            }
        } catch (error) {
            loadMessages();
        }
    };

    const sendMessage = async () => {
        if (newMessage.trim() === '') return;

        const userMessage = { text: newMessage, sender: 'user' };
        const updatedMessages = [...messages, userMessage];
        setMessages(updatedMessages);
        await storeLocal(updatedMessages);
        setNewMessage('');

        let mainPrompt = defaultPrompt + newMessage;
        const dataToSend = { prompt: mainPrompt };
        const url = serverURL + '/api/chat';

        try {
            const response = await axios.post(url, dataToSend);

            if (response.data.success === false) {
                sendMessage();
            } else {
                const botMessage = { text: response.data.text, sender: 'bot' };
                const updatedMessagesWithBot = [...updatedMessages, botMessage];
                setMessages(updatedMessagesWithBot);
                await storeLocal(updatedMessagesWithBot);
            }
        } catch (error) {

        }
    };

    async function storeLocal(messages) {
        try {
            sessionStorage.setItem(mainTopic, JSON.stringify(messages));
        } catch (error) {
            sessionStorage.setItem(mainTopic, JSON.stringify(messages));
        }
    }

    function redirectHome() {
        navigate("/home");
    }

    const style = {
        "root": {
            "base": "h-full",
            "collapsed": {
                "on": "w-16",
                "off": "w-64 max-w-64 min-w-64"
            },
            "inner": "no-scrollbar h-full overflow-y-auto overflow-x-hidden rounded-none border-black dark:border-white md:border-r  bg-white py-4 px-3 dark:bg-black"
        }
    }

    const renderTopicsAndSubtopics = (topics) => {
        try {
            return (
                <div className='mt-2'>
                    {topics.map((topic) => (
                        <Sidebar.ItemGroup key={topic.title}>
                            <div className="relative inline-block text-left">
                                <button
                                    onClick={() => handleOpenClose(topic.title)}
                                    type="button"
                                    className="inline-flex text-start text-base font-bold text-black dark:text-white"
                                >
                                    {topic.title}
                                    <IoIosArrowDown className="-mr-1 ml-2 h-3 w-3 mt-2" />
                                </button>
    
                                {isOpen && key === topic.title && (
                                    <div className="origin-top-right mt-2 pr-4">
                                        <div
                                            className="py-1"
                                            role="menu"
                                            aria-orientation="vertical"
                                            aria-labelledby="options-menu"
                                        >
                                            {topic.subtopics.map((subtopic) => (
                                                <p
                                                    key={subtopic.title}
                                                    onClick={() => handleSelect(topic.title, subtopic.title)}
                                                    className="flex py-2 text-sm flex-row items-center font-normal text-black dark:text-white text-start"
                                                    role="menuitem"
                                                >
                                                    {subtopic.title}
                                                    {subtopic.done === true ? <FaCheck className='ml-2' size={12} /> : <></>}
                                                </p>
                                            ))}
                                        </div>
                                    </div>
                                )}
    
                                {/* Botões "Material Complementar" e "Vídeos Complementares" aparecem somente quando o tópico está aberto */}
                                {isOpen && key === topic.title && (
                                    <>
                                        <div className="mt-4">
                                            <a
                                                href={`https://archive.org/search?query=${topic.title.replace(/ /g, "+")}&sin=TXT&and%5B%5D=language%3A%22Portuguese%22`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="py-2 px-4 text-black dark:text-white text-center w-full"
                                            >
                                                » Material Complementar
                                            </a>
                                        </div>
                                        <div className="mt-4">
                                            <a
                                                href={`https://www.youtube.com/results?search_query=${topic.title.replace(/ /g, "+")}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="py-2 px-4 text-black dark:text-white text-center w-full"
                                            >
                                                » Vídeos Complementares
                                            </a>
                                        </div>
                                    </>
                                )}
                            </div>
                        </Sidebar.ItemGroup>
                    ))}
                </div>
            );
        } catch (error) {
            return (
                <div>
                    {topics.map((topic) => (
                        <Sidebar.ItemGroup key={topic.Title}>
                            <div className="relative inline-block text-left">
                                <button
                                    onClick={() => handleOpenClose(topic.Title)}
                                    type="button"
                                    className="inline-flex text-start text-base font-bold text-black dark:text-white"
                                >
                                    {topic.Title}
                                    <IoIosArrowDown className="-mr-1 ml-2 h-3 w-3 mt-2" />
                                </button>
    
                                {isOpen && key === topic.Title && (
                                    <div className="origin-top-right mt-2 pr-4">
                                        <div
                                            className="py-1"
                                            role="menu"
                                            aria-orientation="vertical"
                                            aria-labelledby="options-menu"
                                        >
                                            {topic.Subtopics.map((subtopic) => (
                                                <p
                                                    key={subtopic.Title}
                                                    onClick={() => handleSelect(topic.Title, subtopic.Title)}
                                                    className="flex py-2 flex-row text-sm items-center font-normal text-black dark:text-white text-start"
                                                    role="menuitem"
                                                >
                                                    {subtopic.Title}
                                                    {subtopic.done === true ? <FaCheck className='ml-2' size={12} /> : <></>}
                                                </p>
                                            ))}
                                        </div>
                                    </div>
                                )}
    
                                {/* Botões "Material Complementar" e "Vídeos Complementares" aparecem somente quando o tópico está aberto */}
                                {isOpen && key === topic.Title && (
                                    <>
                                        <div className="mt-4">
                                            <a
                                                href={`https://archive.org/search?query=${topic.Title.replace(/ /g, "+")}&sin=TXT&and%5B%5D=language%3A%22Portuguese%22`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="py-2 px-4 text-black dark:text-white text-center w-full"
                                            >
                                                » Material Complementar
                                            </a>
                                        </div>
                                        <div className="mt-4">
                                            <a
                                                href={`https://www.youtube.com/results?search_query=${topic.Title.replace(/ /g, "+")}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="py-2 px-4 text-black dark:text-white text-center w-full"
                                            >
                                                » Vídeos Complementares
                                            </a>
                                        </div>
                                    </>
                                )}
                            </div>
                        </Sidebar.ItemGroup>
                    ))}
                </div>
            );
        }
    };
    
    

    return (
        <>
            {!mainTopic ? <></>
                :
                <div>
                    <div className="flex bg-white dark:bg-black md:hidden pb-10 overflow-y-auto">
                        <div className={`fixed inset-0 bg-black opacity-50 z-50 ${isSidebarOpen ? 'block' : 'hidden'}`} onClick={toggleSidebar}></div>
                        <div className="flex-1 flex flex-col overflow-hidden">

                            <div>
                                <Navbar fluid className='py-3 dark:bg-black bg-white border-black dark:border-white md:border-b'>
                                    <Navbar.Brand className='ml-1'>

                                        {isComplete ?
                                            <p onClick={finish} className='mr-3 underline text-black dark:text-white font-normal'>Certificate</p>
                                            :
                                            <div className='w-7 h-7 mr-3'>
                                                <CircularProgressbar
                                                    value={percentage}
                                                    text={`${percentage}%`}
                                                    styles={buildStyles({
                                                        rotation: 0.25,
                                                        strokeLinecap: 'butt',
                                                        textSize: '20px',
                                                        pathTransitionDuration: 0.5,
                                                        pathColor: storedTheme === "true" ? '#fff' : '#000',
                                                        textColor: storedTheme === "true" ? '#fff' : '#000',
                                                        trailColor: storedTheme === "true" ? 'grey' : '#d6d6d6',
                                                    })}
                                                />
                                            </div>
                                        }

                                        <TruncatedText text={mainTopic} len={1} />
                                    </Navbar.Brand>
                                    <div className='flex md:hidden justify-center items-center'>
                                        <div className='mx-2' onClick={redirectHome}><AiFillHome size={20} color={sessionStorage.getItem('darkMode') === 'true' ? 'white' : 'black'} /></div>
                                        <div className='mx-2' onClick={htmlDownload}><HiDownload size={20} color={sessionStorage.getItem('darkMode') === 'true' ? 'white' : 'black'} /></div>
                                        <div>
                                        <ShareOnSocial
    textToShare={sessionStorage.getItem('mName') + " Compartilhe seu curso " + mainTopic}
    link={websiteURL + '/shareable?id=' + courseId + '&public=true'} // Adicionei o parâmetro `public=true` para indicar que o link deve ser público
    linkTitle={sessionStorage.getItem('mName') + " Compartilhe seu curso " + mainTopic}
    linkMetaDesc={sessionStorage.getItem('mName') + " Compartilhe seu curso " + mainTopic}
    linkFavicon={logo}
    noReferer
>
    <div className='mx-2'>
        <RiShareFill size={20} color={sessionStorage.getItem('darkMode') === 'true' ? 'white' : 'black'} />
    </div>
</ShareOnSocial>

                                        </div>
                                        <DarkModeToggle className='inline-flex items-center md:hidden' />
                                        {isSidebarOpen ? (
                                            <FiX
                                                onClick={toggleSidebar}
                                                className='mx-2'
                                                size={20}
                                                color={sessionStorage.getItem('darkMode') === 'true' ? 'white' : 'black'}
                                            />
                                        ) : (
                                            <FiMenu
                                                onClick={toggleSidebar}
                                                className='mx-2'
                                                size={20}
                                                color={sessionStorage.getItem('darkMode') === 'true' ? 'white' : 'black'}
                                            />
                                        )}
                                    </div>
                                    <Navbar.Collapse>
                                        <div className='hidden md:flex justify-center items-center mb-2 mt-2'>
                                            <DarkModeToggle />
                                        </div>
                                    </Navbar.Collapse>
                                </Navbar>

                            </div>

                            <Sidebar
                                aria-label="Default sidebar example"
                                theme={style}
                                className={`md:border-r md:border-black md:dark:border-white dark:bg-black fixed inset-y-0 left-0 w-64  bg-white z-50 overflow-y-auto transition-transform transform lg:translate-x-0 ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'}`}
                            >
                                <LogoComponent isDarkMode={storedTheme} />
                                <Sidebar.Items className='mt-6'>
                                    {jsonData && renderTopicsAndSubtopics(jsonData[mainTopic.toLowerCase()])}
                                </Sidebar.Items>
                                <br />
                                <hr />
                                <Sidebar.Items className='mt-6'>
                                    <p className='text-start flex flex-row items-center text-base font-bold  text-black dark:text-white cursor-pointer' onClick={redirectExam}> {mainTopic} (TESTE FINAL)
                                        {pass === true ? <FaCheck className='ml-2' size={12} /> : <></>}
                                    </p>
                                </Sidebar.Items>
                            </Sidebar>
                            <div className='mx-5 overflow-y-auto bg-white dark:bg-black'>
                                <p className='font-black text-black dark:text-white text-lg'>{selected}</p>

                                <div className='overflow-hidden mt-5 text-black dark:text-white text-base pb-10 max-w-full'>
                                    {type === 'video & text course' ?
                                        <div>
                                            <YouTube key={media} className='mb-5' videoId={media} opts={optsMobile} />
                                            <StyledText text={theory} />
                                        </div>

                                        :
                                        <div>
                                            <StyledText text={theory} />
                                            <img className='overflow-hidden p-10' src={media} alt="Media" />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="fixed bottom-0 left-0 w-full p-4 flex justify-center items-center">
    {/* Botão "Ouvir Conteúdo" */}
    <div className="relative group">
        <button
            onClick={handleReadText} // Atualiza para usar a função que inicia a leitura
            className="py-1 px-4 bg-gray-500 text-white rounded-lg shadow-lg hover:bg-gray-700 transition-all group-hover:opacity-0 text-sm font-serif opacity-80"
        >
            🎧 AUDIODESCRIÇÃO 🎧
        </button>

        {/* Caixa com os 3 botões */}
        <div className="absolute bottom-16 left-1/2 transform -translate-x-1/2 bg-white dark:bg-black p-4 rounded-lg shadow-2xl border-4 border-gray-500 bg-gradient-to-r from-gray-100 to-gray-300 dark:from-gray-700 dark:to-gray-900 flex justify-center space-x-2 opacity-0 group-hover:opacity-100 group-hover:translate-y-0 transition-all duration-500 translate-y-6">
            <button
                onClick={handleReadText} // Atualiza para usar a função que reinicia a leitura
                className="py-1 px-3 bg-gray-500 bg-opacity-30 text-white rounded-lg shadow-lg hover:bg-gray-900 hover:bg-opacity-80 transition-all text-xs"
            >
                Escutar
            </button>
            <button
                onClick={handlePauseReading} // Atualiza para pausar a leitura
                className={`py-1 px-3 ${isReading ? 'bg-gray-500' : 'bg-gray-900'} bg-opacity-60 text-white rounded-lg shadow-lg transition-all text-xs`}
                disabled={!isReading} // Desabilita se não estiver lendo
            >
                Pausar
            </button>

            {/* Seletor de voz */}
            <div className="flex items-center">
                <label htmlFor="voiceSelect" className="text-sm text-gray-800 dark:text-white mr-2">Vozes:</label>
                <select
                    id="voiceSelect"
                    value={selectedVoice}
                    onChange={handleVoiceChange} // Atualiza a voz selecionada
                    className="py-1 px-3 bg-gray-500 text-white rounded-lg shadow-lg"
                >
                    {voices
                        .filter(voice => voice.lang === 'pt-BR') // Filtra as vozes para exibir apenas as de português
                        .map((voice) => (
                            <option key={voice.name} value={voice.name}>
                                {voice.name}
                            </option>
                        ))
                    }
                </select>
            </div>
        </div>
    </div>
</div>



                    <div className='flex flex-row overflow-y-auto h-screen max-md:hidden'>
                        <Sidebar
                            theme={style}
                            aria-label="Default sidebar example">
                            <LogoComponent isDarkMode={storedTheme} />
                            <Sidebar.Items className='mt-6'>
                                {jsonData && renderTopicsAndSubtopics(jsonData[mainTopic.toLowerCase()])}
                            </Sidebar.Items>
                            <br />
                            <hr />
                            <Sidebar.Items className='mt-6'>
                                <p className='text-start flex flex-row items-center text-base font-bold  text-black dark:text-white cursor-pointer' onClick={redirectExam}> {mainTopic} (TESTE FINAL)
                                    {pass === true ? <FaCheck className='ml-2' size={12} /> : <></>}
                                </p>
                            </Sidebar.Items>
                        </Sidebar>
                        <div className='overflow-y-auto flex-grow flex-col'>
                            <Navbar fluid className='py-3 dark:bg-black bg-white border-black dark:border-white md:border-b'>
                                <Navbar.Brand className='ml-1'>
                                    {isComplete ?
                                        <p onClick={finish} className='mr-3 underline text-black dark:text-white font-normal'>Baixar Certificado</p> :
                                        <div className='w-8 h-8 mr-3'>
                                            <CircularProgressbar
                                                value={percentage}
                                                text={`${percentage}%`}
                                                styles={buildStyles({
                                                    rotation: 0.25,
                                                    strokeLinecap: 'butt',
                                                    textSize: '20px',
                                                    pathTransitionDuration: 0.5,
                                                    pathColor: storedTheme === "true" ? '#fff' : '#000',
                                                    textColor: storedTheme === "true" ? '#fff' : '#000',
                                                    trailColor: storedTheme === "true" ? 'grey' : '#d6d6d6',
                                                })}
                                            />
                                        </div>
                                    }
                                    <TruncatedText text={mainTopic} len={4} />
                                </Navbar.Brand>
                                <Navbar.Collapse>
                                    <div className='hidden md:flex justify-center items-center mb-2 mt-2'>
                                        <p onClick={redirectHome} className='font-normal text-black dark:text-white text-base mr-4'>Inicio</p>
                                        <p onClick={htmlDownload} className='font-normal text-black dark:text-white text-base mr-4'>Exportar</p>
                                        <ShareOnSocial
                                            textToShare={sessionStorage.getItem('mName') + " shared you course on " + mainTopic}
                                            link={websiteURL + '/shareable?id=' + courseId}
                                            linkTitle={sessionStorage.getItem('mName') + " shared you course on " + mainTopic}
                                            linkMetaDesc={sessionStorage.getItem('mName') + " shared you course on " + mainTopic}
                                            linkFavicon={logo}
                                            noReferer
                                        >
                                            <button
                                                type="button"
                                                className="bg-transparent"
                                            >
                                                <p className='font-normal text-black dark:text-white text-base mr-4'>Compartilhar</p>
                                            </button>
                                        </ShareOnSocial>
                                        
                                    </div>
                                </Navbar.Collapse>
                            </Navbar>
                            <div className="px-6 py-8 bg-white dark:bg-black" style={{
  display: 'flex',
  justifyContent: 'center',  // Alinha horizontalmente ao centro
  alignItems: 'center',  // Alinha verticalmente ao centro
  minHeight: '100vh',  // Garante que a altura mínima ocupe toda a altura da tela
  width: '100%',  // Garante que a largura ocupe toda a tela
  flexDirection: 'column',  // Organiza os itens em uma coluna (opcional, caso tenha mais elementos)
}}>
  {/* Título principal */}
  <p className="text-2xl font-extrabold text-gray-900 dark:text-gray-100 tracking-wide mb-6 text-center">
    {selected}
  </p>
        {/* Imagem */}
        {type !== "video & text course" && media && !media.includes("youtube.com") && !media.includes("youtu.be") && (
  <img
    className="mt-6 rounded-lg shadow-md w-full object-cover"
    src={media}
    alt="Media"
    style={{ maxWidth: '1000px', maxHeight: '1000px', width: '100%', height: 'auto' }}
  />
)}

  {/* Container de conteúdo */}
  <div className="overflow-hidden p-6 text-gray-800 dark:text-gray-200">
    {type === "video & text course" ? (
      <div>
        {/* Vídeo */}
        <YouTube
          key={media}
          className="mb-6 overflow-hidden"
          videoId={media}
          opts={opts}
        />
        {/* Texto teórico com títulos centralizados */}
        <StyledText
          text={theory}
          render={(content) =>
            content.split("\n").map((line, index) =>
              line.match(/^[A-ZÁÉÍÓÚÂÊÎÔÛÇÑ][^\n.:!?]+$/) ? (
                <p
                  key={index}
                  className="text-lg font-bold text-gray-900 dark:text-gray-100 text-center mb-4"
                >
                  {line}
                </p>
              ) : (
                <p key={index} className="text-base text-left mb-3">
                  {line}
                </p>
              )
            )
          }
        />
        {/* botões front video */}
        <div className="container" style={{ display: 'flex', flexDirection: 'column', gap: '20px', width: '100%' }}>
  {/* Container unificado do material e busca de vídeo */}
  <div id="unifiedContainer" style={{
    width: '100%',  // A largura ocupa toda a tela
    height: 'auto',  // Ajusta a altura conforme o conteúdo
    marginTop: '50px',
    marginBottom: '20px',
    padding: '20px',
    boxSizing: 'border-box',  // Garante que o padding não afete a largura
    textAlign: 'left',  // Alinha o texto à esquerda
    marginLeft: '0',  // Garante que a box fique o mais à esquerda possível
    marginRight: '0', // Garante que a box ocupe toda a largura à esquerda
    maxWidth: 'none',  // Permite que a largura ocupe o máximo possível
    borderLeft: '4px solid black',  // Linha preta de 4px à esquerda
  }}>
    
    {/* Container para os materiais */}
    <div id="materialsContainer" style={{ marginBottom: '20px' }}>
      <div className="material-item">
        <a href="https://archive.org/download/identifier" target="_blank" download>
          <p style={{ color: '#333', fontSize: '16px' }}>
            Clique para acessar materiais relacionados a este tópico!
          </p>
        </a>
      </div>
    </div>

    {/* Container do componente de busca de vídeo */}
    <div className="video-search-container">
      <VideoSearchComponent />
    </div>

  </div>
</div>
      </div>
    ) : (
      <div>
        {/* Texto teórico com títulos centralizados */}
        <StyledText
          text={theory}
          render={(content) =>
            content.split("\n").map((line, index) =>
              line.match(/^[A-ZÁÉÍÓÚÂÊÎÔÛÇÑ][^\n.:!?]+$/) ? (
                <p
                  key={index}
                  className="text-lg font-bold text-gray-900 dark:text-gray-100 text-center mb-4"
                >
                  {line}
                </p>
              ) : (
                <p key={index} className="text-base text-left mb-3">
                  {line}
                </p>
              )
            )
          }
        />
{/* botões front imagem */}

<div className="container" style={{ display: 'flex', flexDirection: 'column', gap: '20px', width: '100%' }}>
  {/* Container unificado do material e busca de vídeo */}
  <div id="unifiedContainer" style={{
    width: '100%',  // A largura ocupa toda a tela
    height: 'auto',  // Ajusta a altura conforme o conteúdo
    marginTop: '50px',
    marginBottom: '20px',
    padding: '20px',
    boxSizing: 'border-box',  // Garante que o padding não afete a largura
    textAlign: 'left',  // Alinha o texto à esquerda
    marginLeft: '0',  // Garante que a box fique o mais à esquerda possível
    marginRight: '0', // Garante que a box ocupe toda a largura à esquerda
    maxWidth: 'none',  // Permite que a largura ocupe o máximo possível
    borderLeft: '4px solid black',  // Linha preta de 4px à esquerda
  }}>
    
    {/* Container para os materiais */}
    <div id="materialsContainer" style={{ marginBottom: '20px' }}>
      <div className="material-item">
        <a href="https://archive.org/download/identifier" target="_blank" download>
          <p style={{ color: '#333', fontSize: '16px' }}>
            Clique para acessar materiais relacionados a este tópico!
          </p>
        </a>
      </div>
    </div>

    {/* Container do componente de busca de vídeo */}
    <div className="video-search-container">
      <VideoSearchComponent />
    </div>

  </div>
</div>
      </div>
    )}
  </div>
</div>

                        </div>
                    </div>
                    <div className='flex flex-col'>
                        <ChatWidget defaultMessage={defaultMessage} defaultPrompt={defaultPrompt} mainTopic={mainTopic} />
                        <NotesWidget courseId={courseId} mainTopic={mainTopic} />
                    </div>
                </div>
            }
        </>
    );
};


export default Course;