import React, { useState, useEffect, useRef } from "react";
import { Video, Sparkles, BookOpen, MessageCircle, Award, Target, Headphones  } from "lucide-react";

const SlideThree = () => {
    const [showIframe, setShowIframe] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const iframeRef = useRef(null);

    useEffect(() => {
        const checkIframeContent = () => {
            if (iframeRef.current) {
                const iframe = iframeRef.current;
                const iframeWindow = iframe.contentWindow;

                if (iframeWindow) {
                    const iframeDocument = iframeWindow.document;

                    // Verifica se o URL do iframe é o link de login
                    if (iframe.src === "https://estudo.net/signin") {
                        setShowIframe(false);
                        setShowErrorMessage(true);
                        return;
                    }

                    // Verifica se o conteúdo do iframe contém as frases específicas
                    if (iframeDocument.body && iframeDocument.body.innerText.includes("Entrar") && iframeDocument.body.innerText.includes("Por favor, insira suas credenciais de acesso.")) {
                        setShowIframe(false);
                        setShowErrorMessage(true);
                    }
                }
            }
        };

        // Verifica o conteúdo do iframe a cada 1 segundo
        const interval = setInterval(checkIframeContent, 1000);

        return () => clearInterval(interval);
    }, [showIframe]);

    return (
        <div className="relative dark:bg-black bg-gradient-to-b from-gray-800 via-gray-900 to-black">
            {/* Onda no topo */}
            <svg className="absolute top-0 left-0 w-full" viewBox="0 0 1440 320" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill="#1f2937" fillOpacity="1" d="M0,224L60,213.3C120,203,240,181,360,176C480,171,600,181,720,170.7C840,160,960,128,1080,122.7C1200,117,1320,139,1380,149.3L1440,160V0H1380C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0H0Z" />
            </svg>

            <div className="flex items-center justify-center text-center relative z-10">
    <div className="relative flex flex-col items-center text-center px-6">
        {/* Box Container */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-6 p-6 mt-6 w-full max-w-screen-xl">

            {/* Box 1 */}
            <div className="flex flex-col items-center justify-center w-full sm:w-80 md:w-72 min-h-[250px] p-6 rounded-xl shadow-md bg-gray-800 text-center border border-gray-700 hover:scale-105 hover:shadow-2xl transition-all duration-300 ease-in-out relative group overflow-hidden">
             <BookOpen size={40} className="text-gray-300" />
                <div className="text-lg font-semibold mt-3 text-white">Suporte Educacional</div>
                <div className="text-sm mt-2 text-gray-400">Proponha um tema de estudo, e nossa plataforma criará materiais exclusivos e personalizados, adaptados ao seu interesse.</div>
                <span className="absolute bottom-0 left-0 w-full h-[2px] bg-gradient-to-r from-blue-600 via-indigo-500 to-purple-600 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-b-xl z-10"></span>
            </div>

            {/* Box 2 */}
            <div className="flex flex-col items-center justify-center w-full sm:w-80 md:w-72 min-h-[250px] p-6 rounded-xl shadow-md bg-gray-800 text-center border border-gray-700 hover:scale-105 hover:shadow-2xl transition-all duration-300 ease-in-out relative group overflow-hidden">
                <Sparkles size={40} className="text-gray-300" />
                <div className="text-lg font-semibold mt-3 text-white">Aprendizado Dinâmico</div>
                <div className="text-sm mt-2 text-gray-400">Escolha os tópicos que deseja explorar e aproveite recursos interativos e materiais de estudo dinâmicos para seu aprendizado.</div>
                <span className="absolute bottom-0 left-0 w-full h-[2px] bg-gradient-to-r from-blue-600 via-indigo-500 to-purple-600 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-b-xl z-10"></span>
            </div>

            {/* Box 3 */}
            <div className="flex flex-col items-center justify-center w-full sm:w-80 md:w-72 min-h-[250px] p-6 rounded-xl shadow-md bg-gray-800 text-center border border-gray-700 hover:scale-105 hover:shadow-2xl transition-all duration-300 ease-in-out relative group overflow-hidden">
                <Video size={40} className="text-gray-300" />
                <div className="text-lg font-semibold mt-3 text-white">Multimidias Interativas</div>
                <div className="text-sm mt-2 text-gray-400">Acesse imagens, repositórios e vídeos exclusivos, proporcionando uma experiência de aprendizado mais fluida, interativa e dinâmica.</div>
                <span className="absolute bottom-0 left-0 w-full h-[2px] bg-gradient-to-r from-blue-600 via-indigo-500 to-purple-600 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-b-xl z-10"></span>
            </div>

            {/* Box 4 */}
            <div className="flex flex-col items-center justify-center w-full sm:w-80 md:w-72 min-h-[250px] p-6 rounded-xl shadow-md bg-gray-800 text-center border border-gray-700 hover:scale-105 hover:shadow-2xl transition-all duration-300 ease-in-out relative group overflow-hidden">
            <Headphones size={40} className="text-gray-300" />
                <div className="text-lg font-semibold mt-3 text-white">Acessibilidade Integrada</div>
                <div className="text-sm mt-2 text-gray-400">A audiodescrição integrada proporciona uma experiência inclusiva, garantindo clareza e acessibilidade para todos.</div>
                <span className="absolute bottom-0 left-0 w-full h-[2px] bg-gradient-to-r from-blue-600 via-indigo-500 to-purple-600 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-b-xl z-10"></span>
            </div>

            {/* Box 5 */}
            <div className="flex flex-col items-center justify-center w-full sm:w-80 md:w-72 min-h-[250px] p-6 rounded-xl shadow-md bg-gray-800 text-center border border-gray-700 hover:scale-105 hover:shadow-2xl transition-all duration-300 ease-in-out relative group overflow-hidden">
                <MessageCircle size={40} className="text-gray-300" />
                <div className="text-lg font-semibold mt-3 text-white">Chat Inteligente</div>
                <div className="text-sm mt-2 text-gray-400">Tire suas dúvidas em tempo real com nosso chat inteligente, sempre disponível para fornecer respostas rápidas e precisas.</div>
                <span className="absolute bottom-0 left-0 w-full h-[2px] bg-gradient-to-r from-blue-600 via-indigo-500 to-purple-600 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-b-xl z-10"></span>
            </div>

            {/* Box 6 */}
            <div className="flex flex-col items-center justify-center w-full sm:w-80 md:w-72 min-h-[250px] p-6 rounded-xl shadow-md bg-gray-800 text-center border border-gray-700 hover:scale-105 hover:shadow-2xl transition-all duration-300 ease-in-out relative group overflow-hidden">
                <Award size={40} className="text-gray-300" />
                <div className="text-lg font-semibold mt-3 text-white">Certificado de Participação</div>
                <div className="text-sm mt-2 text-gray-400">Você receberá um certificado interno, perfeito para monitorar seu progresso e acompanhar sua constante evolução.</div>
                <span className="absolute bottom-0 left-0 w-full h-[2px] bg-gradient-to-r from-blue-600 via-indigo-500 to-purple-600 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-b-xl z-10"></span>
            </div>
        </div>

        <div className="w-full h-[1px] bg-gradient-to-r from-gray-600 via-transparent to-gray-600 mt-12 mb-10"></div>

    </div>
</div>


            {/* Caixa responsiva para título, parágrafo e iframe */}
            <div className="flex justify-center mt-8 px-4 md:px-12 lg:px-20">
                <div className="w-full max-w-7xl p-6 rounded-xl shadow-md bg-gray-800 border border-gray-700 flex flex-col items-center text-center">
                    {/* Ícone de Teste/Experiência acima do título */}
                    <div className="flex justify-center mt-0 mb-0">
                        <Target size={40} className="text-indigo-500" />
                    </div>
                    <h2 className="text-lg sm:text-xl font-semibold text-white mt-3">
                    Confira um exemplo de estudo gerado pela nossa plataforma!
                    </h2>

                    <p className="text-sm sm:text-base text-gray-400 mt-2 leading-snug max-w-[750px]">
                    Interaja com o material abaixo: Explore os módulos e familiarize-se com o conteúdo para entender, na prática, como a plataforma funciona. Por ser um ambiente de testes, algumas funcionalidades podem não estar disponíveis. Cadastre-se e aproveite, de forma ilimitada e gratuita, todos os recursos que temos a oferecer!
                    </p>

                    <div className="w-24 h-1 mx-auto bg-indigo-500 opacity-50 transition-all duration-300 ease-in-out transform scale-x-0 group-hover:scale-x-100"></div>

                    {/* Botão para alternar visibilidade do iframe */}
                    <div className="flex justify-center mt-5">
                        <button
                            onClick={() => setShowIframe(!showIframe)}
                            className="px-4 py-2 rounded-xl bg-gradient-to-r from-indigo-500 via-purple-500 to-blue-500 text-white font-semibold shadow-lg"
                        >
                            {showIframe ? "FECHAR CONTEÚDO" : "VER AGORA"}
                        </button>
                    </div>

                    {/* Caixa do iframe Responsivo */}
                    {showIframe && (
                        <div className="relative w-full mt-5 p-[2px] rounded-xl border-2 border-transparent bg-gradient-to-r from-indigo-500 via-purple-500 to-blue-500">
                            {/* Máscara Desktop */}
                            <div className="hidden md:block absolute top-[5px] right-[40px] w-[240px] h-[40px] bg-transparent z-50"></div>

                            {/* Máscara Mobile */}
                            <div className="block md:hidden absolute top-[5px] left-1/2 transform -translate-x-[65px] w-[170px] h-[40px] bg-transparent z-50"></div>

                            <iframe
                                ref={iframeRef}
                                src="https://estudo.net/shareable?id=67d2f85e517a1a1c2410c74c"
                                className="w-full h-[650px] border border-gray-700 shadow-lg rounded-xl"
                                loading="lazy"
                                frameBorder="0"
                                sandbox="allow-scripts allow-same-origin"
                                style={{ height: '650px', width: '100%', pointerEvents: 'auto' }}
                                title="Description of the embedded content"
                            ></iframe>
                        </div>
                    )}

                    {/* Mensagem de erro */}
                    {showErrorMessage && (
                        <div className="mt-5 p-4 bg-red-500 text-white rounded-xl">
  Lamentamos informar que esta apresentação não é compatível com o seu dispositivo! Para acessar e ver todos os recursos disponíveis, recomendamos realizar o{" "}
  <a
    href="https://estudo.net/signup"
    rel="noopener noreferrer" // Boa prática para links externos
    className="underline font-semibold hover:text-gray-200 transition-colors duration-200"
  >
    cadastro
  </a>{" "}
  e iniciar a utilização da plataforma.
</div>
                    )}
                </div>
            </div>

{/* Seta e Link acima do iframe */}
<div 
  className="flex justify-center mt-8 cursor-pointer" // Adicionei cursor-pointer para indicar que é clicável
  onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} // Função para rolar a tela para o topo
>
  <div className="w-8 h-8 border-t-4 border-l-4 border-gray-300 rotate-45 transform"></div>
</div>

            <div className="flex justify-center mt-2">
                <a href="https://estudo.net/create" className="text-sm sm:text-base md:text-lg lg:text-xl font-semibold text-transparent bg-clip-text bg-gradient-to-r from-indigo-500 to-purple-600">
                    Aproveite diversas outras vantagens, totalmente gratuitas!
                </a>
            </div>
            {/* Espaço transparente de 45px */}
            <div className="h-[80px]"></div>
        </div>
    );
};

export default SlideThree;