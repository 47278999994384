import React, { useState } from 'react';
import Header from '../components/header';
import Footers from '../components/footers';
import { FreeType, FreeCost, FreeTime, MonthType, MonthTime, MonthCost } from '../constants'; // Removido YearType, YearCost, YearTime
import PricingPlan from '../components/pricing';
import { GiCancel } from "react-icons/gi";
import { FaRankingStar } from "react-icons/fa6";
import { FaInfinity } from "react-icons/fa6";
import { Button, Card } from 'flowbite-react';
import { useLocation, useNavigate } from 'react-router-dom';

const Pricing = () => {

    const { state } = useLocation();
    const { header } = state || false;

    const freeData = `{"type": "${FreeType}", "cost": "${FreeCost}", "time": "${FreeTime}", "one": "Gere 10 subtópicos.", "two": "Acesso aos Repositórios.", "three": "Acesso a Certificados.", "four": "Acesso Eterno.", "five": "Teoria - Imagem - Vídeo", "six": "Conteúdos em até 10 idiomas.", "seven": "Bate-Papo Ilimitado Com Tutor Virtual."}`;
    const parsedFreeData = JSON.parse(freeData);

    const monthData = `{"type": "${MonthType}", "cost": "${MonthCost}", "time": "/${MonthTime}", "one":"Gere 10 subtópicos.", "two": "Acesso aos Repositórios.", "three": "Acesso a Certificados.", "four": "Contribuinte.", "five": "Teoria - Imagem - Vídeo", "six": "Conteúdos em até 10 idiomas.", "seven": "Bate-Papo Ilimitado Com Tutor Virtual."}`;
    const parsedMonthData = JSON.parse(monthData);

    // Removido o conteúdo do plano anual (YearData e parsedYearData)

    const navigate = useNavigate();

    function redirectCancel() {
        navigate("/cancellation");
    }

    function redirectRefund() {
        navigate("/refund");
    }

    function redirectBilling() {
        navigate("/billing");
    }

    function redirectContact() {
        navigate("/contact");
    }

    const data = [
        { question: 'Qual é a nossa política de cancelamento ?', answer: <p>Você pode ler nossa política de cancelamento <p className='text-blue-800 underline' onClick={redirectCancel}>aqui</p></p> },
        { question: 'Qual é a nossa política de reembolso ?', answer: <p>Você pode ler nossa política de reembolso <p className='text-blue-800 underline' onClick={redirectRefund}>aqui</p></p> },
        { question: 'Qual é a nossa política de assinatura e cobrança ?', answer: <p>Você pode ler nossa política de assinatura e cobrança <p className='text-blue-800 underline' onClick={redirectBilling}>aqui</p></p> },
        { question: 'Quais são os gateways de pagamento disponíveis ?', answer: 'Nossa plataforma oferece suporte a pagamento seguro pelo banco virtual Stripe.' },
    ];

    const [expandedItem, setExpandedItem] = useState(null);

    const handleExpand = (index) => {
        setExpandedItem(expandedItem === index ? null : index);
    };

    const style = {
        "root": {
            "base": "max-w-sm max-md:max-w-xs flex rounded-none border border-black bg-white shadow-none dark:border-white dark:bg-black m-4",
            "children": "flex h-full flex-col justify-center gap-3 p-5",
            "horizontal": {
                "off": "flex-col",
                "on": "flex-col md:max-w-xl md:flex-row"
            },
            "href": "hover:bg-white dark:hover:bg-black"
        },
        "img": {
            "base": "",
            "horizontal": {
                "off": "rounded-none",
                "on": "h-96 w-full rounded-none object-cover md:h-auto md:w-48 md:rounded-none md:rounded-l-lg"
            }

        }
    }

    return (
        <div className='h-screen flex flex-col'>
            <Header isHome={header} className="sticky top-0 z-50" />
            <div className='dark:bg-black flex-1'>
                <div className='flex-1 flex flex-col items-center justify-center'>
                    <h1 className="text-6xl font-black mt-14 max-md:text-3xl dark:text-white">Seja um Apoiador!</h1>
                    <p className="text-center text-black mt-6 max-w-2xl font-medium max-md:text-xs dark:text-white">
                    Nosso serviço é completamente gratuito! No entanto, se você acredita no nosso projeto e deseja nos ajudar a continuar operando e a aprimorar nossos recursos ao longo do tempo, considere apoiar-nos com uma doação na opção "Cafézinho do Desenvolvedor" abaixo.
                    </p>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 py-14 gap-6"> {/* Usando grid ao invés de flex */}
                        {/* Plano Grátis */}
                        <div className="flex flex-col items-center justify-center">
                            <PricingPlan data={parsedFreeData} />
                        </div>

                        {/* Plano Mensal */}
                        <div className="flex flex-col items-center justify-center">
                            <PricingPlan data={parsedMonthData} />
                        </div>

                        {/* Removido o bloco de renderização do plano anual */}
                    </div>
                    <div className="dark:bg-black pt-16">
                        <div className="flex items-center justify-center text-center">
                            <div className="text-4xl font-black dark:text-white max-md:text-2xl">Por que devo contribuir?</div>
                        </div>
                        <div className="lg:flex pt-10 max-md:py-14">

                            <Card key={1} theme={style}>
                                <FaInfinity className="text-4xl max-md:text-3xl dark:text-white" />
                                <h5 className='text-xl font-black tracking-tight text-black dark:text-white'>
                                    Ajude um Desenvolvedor!
                                </h5>
                                <p className='font-normal text-sm text-black dark:text-white'>Ao contribuir você ajuda o nosso desenvolvedor independente dessa plataforma.</p>
                            </Card>

                            <Card key={2} theme={style}>
                                <FaRankingStar className="text-4xl max-md:text-3xl dark:text-white" />
                                <h5 className='text-xl font-black tracking-tight text-black dark:text-white'>
                                    Mantenha a Plataforma Ativa!
                                </h5>
                                <p className='font-normal text-sm text-black dark:text-white'>Ao contribuir você gera fundos, garantindo a existência da plataforma e o desenvolvimento de melhoria.</p>
                            </Card>

                            <Card key={3} theme={style}>
                                <GiCancel className="text-4xl max-md:text-3xl  dark:text-white" />
                                <h5 className='text-xl font-black tracking-tight text-black dark:text-white'>
                                    Ajude quando quiser!
                                </h5>
                                <p className='font-normal text-sm text-black dark:text-white'>Você pode ajudar sempre que quiser! E, quando não puder, continuará a utilizar normalmente.</p>
                            </Card>
                        </div>
                    </div>
                    <div className="flex items-center justify-center text-center flex-col px-6 max-md:px-2 pt-16">
                        <h1 className="text-4xl font-black dark:text-white max-md:text-2xl mb-10">Dúvidas Frequêntes</h1>
                        <ul>
                            {data.map((item, index) => (
                                <li key={index} className="mb-4">
                                    <Button
                                        className='w-96 max-sm:max-w-xs mb-6 items-start justify-start text-start border-black dark:border-white dark:bg-black dark:text-white bg-white text-black font-bold rounded-none  enabled:hover:bg-white enabled:focus:bg-white enabled:focus:ring-transparent dark:enabled:hover:bg-black dark:enabled:focus:bg-black dark:enabled:focus:ring-transparent'
                                        onClick={() => handleExpand(index)}
                                    >
                                        {data[index].question}
                                    </Button>
                                    {expandedItem === index && (
                                        <div className='max-w-sm max-sm:max-w-xs mb-6 items-start justify-start text-start border-black dark:border-white dark:bg-black dark:text-white bg-white text-black font-normal rounded-none enabled:hover:bg-white enabled:focus:bg-white enabled:focus:ring-transparent dark:enabled:hover:bg-black dark:enabled:focus:bg-black dark:enabled:focus:ring-transparent'>
                                            {item.answer}
                                        </div>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="flex items-center justify-center text-center py-16 flex-col">
                        <div className="text-4xl font-black dark:text-white max-md:text-2xl">Para questões particulares</div>
                        <Button className='max-w-xs my-10 items-center justify-center text-center border-black dark:border-white dark:bg-black dark:text-white bg-white text-black font-bold rounded-none w-full enabled:hover:bg-white enabled:focus:bg-white enabled:focus:ring-transparent dark:enabled:hover:bg-black dark:enabled:focus:bg-black dark:enabled:focus:ring-transparent' onClick={redirectContact}>Entre em Contato</Button>
                    </div>
                </div>
            </div>
            <Footers className="sticky bottom-0 z-50" />
        </div>
    );
};

export default Pricing;
