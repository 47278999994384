import React from 'react';
import { useNavigate } from "react-router-dom";

const SlideOne = () => {
    const navigate = useNavigate();

    function redirectSignIn() {
        navigate("/signin");
    }
    function redirectSignUp() {
        navigate("/signup");
    }
    function redirectAbout() {
        navigate("/about");
    }

    const getFormattedDate = () => {
        const now = new Date();
        const day = now.getDate().toString();
        const month = now.toLocaleString('pt-BR', { month: 'long' });
        const year = now.getFullYear();
        return { 
            formattedDate: `${day} de ${month} de ${year}.`, 
            onlineText: (
                <span className="inline-flex items-center">
                    <span>&nbsp;</span> {/* Espaço antes da bola verde */}
                    <span className="w-2 h-2 rounded-full bg-green-500" title="Servidor Online"></span> {/* Tooltip com o texto "Servidor Online" */}
                    <span>&nbsp;Servidores:&nbsp;Online&nbsp;›&nbsp;</span> {/* Espaço depois da bola verde */}
                </span>
            ) 
        };
    };

    const { formattedDate, onlineText } = getFormattedDate();

    return (
        <div className="flex flex-col items-center bg-gradient-to-b from-black via-gray-900 to-gray-800 dark:bg-black p-4 pt-20">
            <p className="text-xs text-center text-white mb-0 inline-flex items-center">
                {onlineText} <span className="text-gray-300">{formattedDate}</span> {/* Data em cor mais suave */}
            </p>
            
            <h1 className="text-7xl font-black max-md:text-5xl text-center mb-5 mt-5 bg-gradient-to-r from-blue-600 via-indigo-500 to-purple-600 bg-clip-text text-transparent font-serif max-w-4/5">
            Plataforma de Estudos
            </h1>

            <div className="flex flex-col md:flex-row mb-0 mt-0 gap-2 md:gap-4"> 
                <button
                    onClick={redirectSignUp}
                    className="bg-gradient-to-r from-indigo-600 via-blue-500 to-purple-500 text-white px-3 py-2 text-xs font-medium rounded-full hover:bg-gradient-to-r hover:from-indigo-500 hover:via-blue-400 hover:to-purple-400 transition-all duration-300 dark:bg-gradient-to-r dark:from-indigo-700 dark:via-blue-700 dark:to-purple-700 dark:text-white dark:hover:bg-gradient-to-r dark:hover:from-indigo-600 dark:hover:via-blue-600 dark:hover:to-purple-600 opacity-90 hover:opacity-100 flex items-center gap-1.5"
                >
                    Iniciar Gratuitamente
                    <svg width="12" height="12" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="inline-block align-middle rotate-45">
                        <path d="M4.97362 2.37505C5.30113 2.04753 5.83214 2.04753 6.15966 2.37505L10.3529 6.56833C10.6805 6.89584 10.6805 7.42685 10.3529 7.75436C10.0254 8.08188 9.49441 8.08188 9.1669 7.75436L6.40529 4.99276L6.40529 13.0319C6.40529 13.4951 6.02981 13.8706 5.56664 13.8706C5.10346 13.8706 4.72798 13.4951 4.72798 13.0319L4.72798 4.99276L1.96638 7.75436C1.63886 8.08188 1.10786 8.08188 0.78034 7.75436C0.452825 7.42685 0.452825 6.89584 0.78034 6.56832L4.97362 2.37505Z" fill="currentColor"/>
                    </svg>
                </button>

                <div className="flex flex-col md:flex-row gap-1 md:gap-2 max-md:hidden"> 
                    <button
                        onClick={redirectSignUp}
                        className="bg-transparent text-white px-3 py-1 text-xs font-medium rounded-lg transition-all duration-300 hover:text-indigo-400 flex items-center group"
                    >
                        <span className="hover:underline">Baixar Aplicativo Desktop</span>
                        <span className="ml-0.5">›</span>
                    </button>
                    <button
                        onClick={redirectAbout}
                        className="bg-transparent text-white px-3 py-1 text-xs font-medium rounded-lg transition-all duration-300 hover:text-indigo-400 flex items-center group"
                    >
                        <span className="hover:underline">Nossa Missão</span>
                        <span className="ml-0.5">›</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SlideOne;
