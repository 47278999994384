//BRAND
export const name = 'Estudo.net';
export const company = 'Estudo.net';
export const websiteURL = 'https://estudo.net';
export const serverURL = 'https://finalarpa.onrender.com';
export const logo = 'https://i.imgur.com/iXhkK9o.png';
export const razorpayEnabled = false;
export const paypalEnabled = false;
export const stripeEnabled = true;
export const paystackEnabled = false;
export const flutterwaveEnabled = false;

//PRICING :-

//FREE 
export const FreeType = 'Plano Aprendizagem';
export const FreeCost = 0;
export const FreeTime = '/Eterno.';

//MONTHLY 
export const MonthType = 'Cafézinho do Desenvolvedor!';
export const MonthCost = 10;
export const MonthTime = 'Ajuda.';

//YEARLY 
export const YearType = 'Cafézinho do desenvolvedor!';
export const YearCost = 10;
export const YearTime = 'Ajuda';

//TESTIMONIAL
export const review = "Desenvolvemos esta aplicação com o objetivo de auxiliar estudantes de todos os níveis em suas atividades acadêmicas e pesquisas científicas.";
export const from = "Mark Wagemann";
export const profession = 'CEO';
export const photoURL = 'https://i.imgur.com/9FsZfVG.png';

//PAYPAL
export const paypalPlanIdOne = "P-1EM732768S920784HMWKW3OA";
export const paypalPlanIdTwo = "P-8T744865W27080359MWOCE5Q";

//RAZORPAY
export const razorpayKeyId = "rzp_test_uqALjZHyTyuiOm";
export const razorpayPlanIdOne = "plan_NMvvtDfznbRp6V";
export const razorpayPlanIdTwo = "plan_NMRc9HARBQRLWA";

//STRIPE
export const stripePlanIdOne = "price_1QnXE3JuOzaeVD3g7sFJeAne"; //id10
export const stripePlanIdTwo = "price_1QnXE3JuOzaeVD3g7sFJeAne"; //id100

//PAYSTACK
export const paystackPlanIdOne = "PLN_ouqmm8eo6i2k9k8"; //id10
export const paystackPlanIdTwo = "PLN_1v1xqb8io9t5lis"; //id100
export const amountInZarOne = '170';
export const amountInZarTwo = '1871';

//FLUTTERWAVE
export const flutterwavePlanIdOne = "67960";
export const flutterwavePlanIdTwo = "67961";
export const flutterwavePublicKey = "FLWPUBK_TEST-6ee1faf6460ea587f510a024ac4c2b23-X";

//SOCIAL SIGNIN
export const googleClientId = "145154561569-ti0kprbhqpi1g7ckeqirrng7jhpdumti.apps.googleusercontent.com";
export const facebookClientId = "340298698481801";