import React, { useEffect, useRef, useState } from 'react';
import Header from '../components/header';
import Footers from '../components/footers';
import { Button } from 'flowbite-react';
import { AiOutlineLoading } from 'react-icons/ai';
import Logos from '../res/img/certificate.png';
import logo from '../res/img/logoblack.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { toPng } from 'html-to-image';
import { name } from '../constants';

const Certificate = () => {
    const [processing, setProcessing] = useState(false);
    const [loading, setLoading] = useState(true);
    
    const userName = sessionStorage.getItem('mName');
    const { state } = useLocation();
    const navigate = useNavigate();
    const { courseTitle = '', end = '' } = state || {};
    const pdfRef = useRef(null);

    useEffect(() => {
        if (!courseTitle) {
            navigate("/create");
        }
    }, [state, courseTitle, navigate]);

    const handleDownload = async () => {
        setProcessing(true);
        toPng(pdfRef.current, { cacheBust: false })
            .then((dataUrl) => {
                const link = document.createElement("a");
                link.download = "certificate.png";
                link.href = dataUrl;
                link.click();
                showToast("Downloaded");
            })
            .catch(() => {
                //DO NOTHING
            });
    };

    function checkAndFormatDate(dateString) {
        const dateObj = new Date(dateString);
        return isNaN(dateObj.getTime()) ? new Date().toLocaleDateString('pt-BR') : dateObj.toLocaleDateString('pt-BR');
    }

    const showToast = async (msg) => {
        setProcessing(false);
        toast(msg, {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
        });
    };

    return (
        <div className='h-screen flex flex-col'>
            {loading && (
                <div className="fixed inset-0 bg-white dark:bg-black flex flex-col justify-center items-center z-50">
                    <AiOutlineLoading className="animate-spin text-4xl" />
                    <p className="text-xl mt-4">Gerando Certificado...</p>
                </div>
            )}
            <Header isHome={true} className="sticky top-0 z-50" />
            <div className='dark:bg-black flex-1 flex flex-col justify-center items-center p-4'>
                <p className='text-center font-black text-4xl text-black dark:text-white'>Parabéns!🎉</p>
                <p className='text-center font-normal text-black py-4 dark:text-white'>
                    Validamos que, <strong>{userName}</strong> concluiu o módulo: "<strong>{courseTitle}</strong>" com êxito.<br /> 
                    Baixe seu certificado de participação agora mesmo! ( Caso seu certificado esteja sem nome, altere seu nome em <a href="https://arpanetwork.net/profile" className="text-blue-500 hover:underline">perfil</a>. )
                </p>
                <Button 
                    onClick={handleDownload} 
                    isProcessing={processing} 
                    processingSpinner={<AiOutlineLoading className="h-6 w-6 animate-spin" />} 
                    className='items-center justify-center rounded-none text-center dark:bg-white bg-black text-white dark:text-black font-bold max-w-sm' 
                    type="submit">
                    Baixar
                </Button>
                <div className='relative flex justify-center items-center mt-8 w-full max-w-4xl'>
                    <div ref={pdfRef} className='relative w-full max-w-4xl h-auto flex justify-center items-center'>
                        {/* Adiciona onLoad na imagem e remove a tela de loading ao carregar */}
                        <img 
                            src={Logos} 
                            alt="Certificado" 
                            className='w-full h-auto' 
                            onLoad={() => setLoading(false)}
                        />
                        <p className='absolute text-4xl font-black italic max-lg:text-2xl max-md:text-xl text-center' style={{ top: '47%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                            {userName}
                        </p>
                        <p className='absolute text-xl font-medium max-lg:text-lg max-md:text-[9px] text-center' style={{ top: '67.5%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                            em {checkAndFormatDate(end)}
                        </p>
                        <div className='absolute text-center' style={{ top: '63%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                            <p className='text-xl font-bold capitalize max-lg:text-lg max-md:text-[9px]'>
                                "{courseTitle}"
                            </p>
                        </div>
                        <div className='absolute bottom-10 left-1/2 transform -translate-x-1/2 flex flex-col items-center'>
                            <img className='w-1/6 h-auto' src={logo} alt='Logo' />
                            <p className='text-xl font-semibold text-center max-lg:text-lg max-md:text-xs'>
                                {name}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <p className="text-center font-bold text-red-600 mb-5">ESTE CERTIFICADO NÃO É RECONHECIDO PELO MEC</p>
            <Footers className="sticky bottom-0 z-50" />
        </div>
    );
};

export default Certificate;
