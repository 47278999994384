import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { serverURL } from '../constants';
import { Card, Spinner } from 'flowbite-react';
import { useNavigate } from 'react-router-dom';
import found from '../res/img/found.svg';

const UserCourses = ({ userId }) => {
    const [courses, setCourses] = useState([]);
    const [processing, setProcessing] = useState(true);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);

    const fetchCourseImage = async (title) => {
        const PEXELS_API_KEY = 'e9EWvn3Lcw9lL18WOBMDeg6NiiQJjayyX0fP59tFFsCGgcpR51Xo2Pnp'; // Sua chave API
        try {
            const response = await axios.get(
                `https://api.pexels.com/v1/search?query=${encodeURIComponent(title)}&per_page=1&orientation=landscape`,
                { headers: { Authorization: PEXELS_API_KEY } }
            );
            // Usando a imagem de maior qualidade disponível
            const photo = response.data.photos[0];
            if (photo && photo.width > photo.height) {
                return photo.src.large || photo.src.medium || "https://exemplo.com/imagens/padrao.jpg"; // Prioriza a maior qualidade disponível
            }
            return "https://exemplo.com/imagens/padrao.jpg";
        } catch (error) {
            console.error("Erro ao buscar imagem:", error);
            return "https://exemplo.com/imagens/padrao.jpg";
        }
    };

    const fetchUserCourses = useCallback(async () => {
        setProcessing(page === 1);
        setLoadingMore(page > 1);
        const postURL = `${serverURL}/api/courses?userId=${userId}&page=${page}&limit=9`;
        try {
            const response = await axios.get(postURL);
            if (response.data.length === 0) {
                setHasMore(false);
            } else {
                // Adicionando imagem dinâmica ao curso com base no título (mainTopic)
                const coursesWithImages = await Promise.all(response.data.map(async (course) => {
                    const image = await fetchCourseImage(course.mainTopic); // Agora o título é usado para a busca
                    return { ...course, photo: image };
                }));
                setCourses((prevCourses) => [...prevCourses, ...coursesWithImages]);
            }
        } catch (error) {
            console.error("Error fetching courses:", error);
        } finally {
            setProcessing(false);
            setLoadingMore(false);
        }
    }, [userId, page]);

    useEffect(() => {
        fetchUserCourses();
    }, [fetchUserCourses]);

    const handleScroll = useCallback(() => {
        if (!hasMore || loadingMore) return;
        const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
        if (scrollTop + clientHeight >= scrollHeight - 100) {
            setPage((prevPage) => prevPage + 1);
        }
    }, [hasMore, loadingMore]);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [handleScroll]);

    const navigate = useNavigate();
    const redirectGenerate = () => navigate("/create");

    const handleCourse = async (content, mainTopic, type, courseId, completed, end) => {
        const postURL = serverURL + '/api/getmyresult';
        const response = await axios.post(postURL, { courseId });
        console.log(response.data.lang)
        if (response.data.success) {
            const jsonData = JSON.parse(content);
            sessionStorage.setItem('courseId', courseId);
            sessionStorage.setItem('first', completed);
            sessionStorage.setItem('jsonData', JSON.stringify(jsonData));
            let ending = '';
            if (completed) ending = end;
            navigate('/course', {
                state: {
                    jsonData,
                    mainTopic: mainTopic.toUpperCase(),
                    type: type.toLowerCase(),
                    courseId,
                    end: ending,
                    pass: response.data.message,
                    lang: response.data.lang
                }
            });
        } else {
            const jsonData = JSON.parse(content);
            sessionStorage.setItem('courseId', courseId);
            sessionStorage.setItem('first', completed);
            sessionStorage.setItem('jsonData', JSON.stringify(jsonData));
            let ending = '';
            if (completed) ending = end;
            navigate('/course', {
                state: {
                    jsonData,
                    mainTopic: mainTopic.toUpperCase(),
                    type: type.toLowerCase(),
                    courseId,
                    end: ending,
                    pass: false,
                    lang: response.data.lang
                }
            });
        }
    };

    const handleCertificate = (mainTopic, end) => {
        const ending = new Date(end).toLocaleDateString();
        navigate('/certificate', { state: { courseTitle: mainTopic, end: ending } });
    };

    return (
        <div className='my-4'>
            {processing ? (
                <div className="text-center h-screen w-screen flex items-center justify-center">
                    <Spinner size="xl" className='fill-black dark:fill-white' />
                </div>
            ) : (
                <>
                    {courses.length === 0 ? (
                        <div className="text-center h-center flex flex-col items-center justify-center">
                            <img alt='img' src={found} className='max-w-sm h-3/6' />
                            <p className='text-black font-black dark:text-white text-xl'>Nada foi encontrado!</p>
                            <button onClick={redirectGenerate} className='bg-black text-white px-5 py-2 mt-4 font-medium dark:bg-white dark:text-black'>
                                Gerar Curso
                            </button>
                        </div>
                    ) : (
                        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4'>
                            {courses.map((course) => (
                                <div key={course._id} className='w-full'>
                                    <Card theme={{ base: "flex flex-col rounded-none border border-black bg-white shadow-none dark:border-white dark:bg-black mx-2 my-4" }}>
                                        <div 
                                            style={{
                                                width: "100%", // Garantir que a imagem ocupe toda a largura do card
                                                height: "200px", // Definir altura fixa para a imagem
                                                backgroundImage: `url(${course.photo})`,
                                                backgroundSize: "cover", // Ajuste da imagem para cobrir o card sem deformar
                                                backgroundPosition: "center center", // Centraliza a imagem
                                                borderRadius: "0.375rem", // Borda arredondada no card
                                            }}
                                        />
<h5 
    className='text-xl font-black tracking-tight text-black dark:text-white' 
    style={{
        overflow: "hidden", 
        textOverflow: "ellipsis", 
        display: "block", // Garante que o título ocupe várias linhas se necessário
        lineHeight: "1.2", // Controla o espaçamento entre as linhas do título
        height: "3rem", // Define uma altura fixa para o título (pode ser ajustada)
        maxHeight: "3rem", // Garante que o título não ultrapasse essa altura
        overflow: "hidden", // Oculta o conteúdo que ultrapassar a altura definida
        textOverflow: "ellipsis", // Adiciona os "..."
        wordBreak: "break-word", // Quebra palavras longas
    }}
>
    {course.mainTopic.toUpperCase()}
</h5>

                                        <p className='font-normal text-sm capitalize text-black dark:text-white'>
                                            {course.type}
                                        </p>
                                        <p className='font-normal text-sm text-black dark:text-white'>
                                            {new Date(course.date).toLocaleDateString()}
                                        </p>
                                        <div className='flex-row flex space-x-4'>
                                            <button onClick={() => handleCourse(course.content, course.mainTopic, course.type, course._id, course.completed, course.end)} className='bg-black text-white px-5 py-2 font-medium dark:bg-white dark:text-black'>
                                                Acessar
                                            </button>
                                            {course.completed ? (
                                                <button onClick={() => handleCertificate(course.mainTopic, course.end)} className='border-black text-black border px-5 py-2 font-medium dark:border-white dark:text-white'>
                                                    Certificado
                                                </button>
                                            ) : null}
                                        </div>
                                    </Card>
                                </div>
                            ))}
                        </div>
                    )}
                    {loadingMore && (
                        <div className="text-center my-4">
                            <Spinner size="lg" className='fill-black dark:fill-white' />
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default UserCourses;
