import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Facebook, Instagram } from 'lucide-react';
import { X as XIcon } from 'lucide-react';
import { company, websiteURL } from '../constants';

const Footers = () => {
  const navigate = useNavigate();

  function redirectAbout() {
    navigate("/about");
  }

  function redirectContact() {
    navigate("/contact");
  }

  function redirectTerms() {
    navigate("/terms");
  }

  function redirectPrivacy() {
    navigate("/privacy");
  }

  return (
    <footer className="bg-gradient-to-b from-gray-800 via-gray-900 to-black py-5">
      <div className="max-w-screen-xl mx-auto text-white px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          {/* Seção de Conecte-se */}
          <div className="space-y-3">
            <h3 className="text-xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-indigo-500 to-purple-600">
              Conecte-se Conosco
            </h3>
            <p className="text-sm text-gray-300">Fique por dentro das novidades e atualizações.</p>
            <div className="flex space-x-3">
              <a href="#" className="text-gray-300 hover:text-indigo-400 transition duration-300">
              <Facebook size={24} />
              </a>
              <a href="#" className="text-gray-300 hover:text-indigo-400 transition duration-300">
                <Instagram size={24} />
              </a>
              <a href="#" className="text-gray-300 hover:text-indigo-400 transition duration-300">
                <XIcon size={24} />
              </a>
            </div>
          </div>

          {/* Seção de Links */}
          <div className="space-y-3">
            <h4 className="text-sm font-semibold text-gray-300">Links Úteis</h4>
            <ul className="space-y-2 text-sm text-gray-400">
              <li><button className="hover:text-indigo-400 transition duration-300" onClick={redirectAbout}>Sobre Nós</button></li>
              <li><button className="hover:text-indigo-400 transition duration-300" onClick={redirectTerms}>Termos de Uso</button></li>
              <li><button className="hover:text-indigo-400 transition duration-300" onClick={redirectPrivacy}>Política de Privacidade</button></li>
              <li><button className="hover:text-indigo-400 transition duration-300" onClick={redirectContact}>Contato</button></li>
            </ul>
          </div>

          {/* Seção de Contato */}
          <div className="space-y-3">
            <h4 className="text-sm font-semibold text-gray-300"></h4>
            <ul className="text-gray-400 space-y-2">
              <li><a href="mailto:contato@empresa.com" className="text-indigo-400 hover:text-indigo-500"></a></li>
              <li><a href="tel:+553126261208" className="text-indigo-400 hover:text-indigo-500"></a></li>
            </ul>
          </div>

          {/* Seção de Newsletter */}
          <div className="space-y-3">
            <h4 className="text-sm font-semibold text-gray-300">Assine nossa Newsletter</h4>
            <p className="text-sm text-gray-400">Receba atualizações diretamente no seu e-mail.</p>
            <form className="flex space-x-2">
              <input type="email" placeholder="Seu e-mail" className="p-1 text-gray-900 rounded-md w-2/3 focus:outline-none bg-gradient-to-r from-gray-700 via-gray-800 to-gray-700 placeholder-gray-400" />
              <button type="submit" className="bg-indigo-600 text-white p-1 rounded-md hover:bg-indigo-700 transition duration-300">Inscrever</button>
            </form>
          </div>
        </div>

        {/* Divider */}
        <div className="border-t-2 border-gray-600 my-4" />

        {/* Copyright */}
        <div className="flex justify-between items-center">
          <p className="text-xs text-gray-400">© {new Date().getFullYear()} {company}. Todos os direitos reservados.</p>
          <div className="text-xs text-gray-400">
            <a href={websiteURL} className="hover:text-indigo-400">Sobre Cookies</a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footers;
